import { API_METHOD, apiRequest } from '../utils/apiClient';

export const fetchNotifications = (token, hacId) =>
  apiRequest(API_METHOD.GET, `/hac/notification?hacId=${hacId}`, token);

export const getModels = (token) => apiRequest(API_METHOD.GET, `/hac/vehicle-models`, token);

export const fetchVehicles = (token, hacId) =>
  apiRequest(API_METHOD.GET, `/hac/vehicles/${hacId}`, token);

export const deleteVehicle = async (token, hacId, vehicleId) => {
  return await apiRequest(
    API_METHOD.DELETE,
    `/hac/vehicle/${vehicleId}?hacId=${hacId}`,
    token
  );
};


export const upsertVehicle = (token, hacId, newVehicle) =>
  apiRequest(API_METHOD.POST, `/hac/vehicle?hacId=${hacId}`, token, newVehicle);

export const getAcademicTitles = (token, areaCode) =>
  apiRequest(API_METHOD.GET, `/hac/academic-titles?area_code=${areaCode}`, token);

export const getCountries = () =>
  apiRequest(API_METHOD.GET, `/hac/countries`);


export const getHacData = (token, hacId) => 
  apiRequest(API_METHOD.GET, `/hac?hacId=${hacId}`, token);


export const deleteAddress = (token, hacId, addressId) =>
  apiRequest(
    API_METHOD.DELETE,
    `/hac/address/${addressId}?hacId=${hacId}`,
    token
  );

export const updateHac = (token, hacId, payload) =>
  apiRequest(API_METHOD.PUT, `/hac/${hacId}`, token, payload);

export const upsertPreference = (token, hacId, preference) =>
  apiRequest(
    API_METHOD.POST,
    `/hac/preference?hacId=${hacId}`,
    token,
    preference
);

export const getPreferences = (token, hacId) =>
  apiRequest(API_METHOD.GET, `/hac/preferences/${hacId}`, token);

export const deletePreference = (token, preferenceId, hacId) =>
  apiRequest(
    API_METHOD.DELETE,
    `/hac/preference/${preferenceId}?hacId=${hacId}`,
    token
);

export const getPreferencesCategories = (token) =>
  apiRequest(API_METHOD.GET, `/hac/preferences-categories`, token);

export const getPreferencesByHacAndCategory = (token, hacId, cat) =>
  apiRequest(
    API_METHOD.GET,
    `/hac/category-preferences/${hacId}?categoryId=${cat}`,
    token
  );


