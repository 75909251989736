import './table-pma.scss';
import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import Grid from '@mui/material/Grid2';
import {Alert, Snackbar} from '@mui/material';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";

function TablePma({reload, onDataLoaded}) {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(false);

  /* Menu */
  const [anchorEl, setAnchorEl] = useState({});
  const handleClick = (id) => (event) => {
    setAnchorEl({...anchorEl, [id]: event.currentTarget});
  };
  const handleClose = () => {
    setAnchorEl({});
  };
  const navigate = useNavigate();

  const [pmas, setPmas] = useState([]);
  const [filteredPmas, setFilteredPmas] = useState([]);
  const [countries, setCountries] = useState([]);
  const [markets] = useState([
    { name: 'AU_CZ_SK', countries: ['AT', 'CZ', 'SK'] },
    { name: 'Benelux', countries: ['BE', 'LUX', 'NL'] },
    { name: 'EIRO', countries: ['PL'] },
    { name: 'France', countries: ['FR'] },
    { name: 'Germany', countries: ['DE'] },
    { name: 'Iberia', countries: ['ES', 'PT'] },
    { name: 'Italy', countries: ['IT'] },
    { name: 'Switzerland', countries: ['CH'] },
    { name: 'Turkey', countries: ['TR'] },
  ]);
  const [filters, setFilters] = useState({
    name: '',
    market: '',
    country: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchData = async () => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/admin/pmas`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Cache-Control': 'no-cache',
      },
    };

    try {
      const response = await apiClient.get(url, config);
      if (response.status === 200) {
        const data = response.data;
        const storedFilters = JSON.parse(sessionStorage.getItem('admin-pma-filters') || '{}');
        const initialFilters = {
          ...filters,
          ...storedFilters,
        };
        setFilters(initialFilters);
        setPmas(data);
        applyFilters(initialFilters, data);
        setDataLoaded(true);
        onDataLoaded();

        const market = markets.find((m) => m.name === initialFilters.market);
        const availableCountries = market ? market.countries : [...new Set(data.map((pma) => pma.area_code))];
        setCountries(availableCountries);
      } else if (response.status === 204) {
        setDataLoaded(true);
      }
    } catch (error) {
      if (error.response) {
        setError(true);
        setDataLoaded(false);
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    }
  };

  useEffect(() => {
    if (reload) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [reload, token, onDataLoaded, t]);

  const handleFilterChange = (e) => {
    const {name, value} = e.target;

    if (name === 'market') {
      const selectedMarket = markets.find((market) => market.name === value);
      const updatedCountries = selectedMarket ? selectedMarket.countries : [...new Set(pmas.map((pma) => pma.area_code))];
      setCountries(updatedCountries);
      setFilters({
        ...filters,
        market: value,
        country: '', // Resetea el país al cambiar el mercado
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  const applyFilters = (appliedFilters = filters, pmasData = pmas) => {
    let filtered = [...pmasData];
    if (appliedFilters.name) {
      filtered = filtered.filter(
        (pma) =>
          pma.name.toLowerCase().includes(appliedFilters.name.toLowerCase()) ||
          pma.last_name.toLowerCase().includes(appliedFilters.name.toLowerCase())
      );
    }
    if (appliedFilters.market) {
      const selectedMarket = markets.find(
        (market) => market.name === appliedFilters.market
      );
      if (selectedMarket) {
        filtered = filtered.filter((pma) =>
          selectedMarket.countries.includes(pma.area_code)
        );
      }
    }
    if (appliedFilters.country) {
      filtered = filtered.filter((pma) => pma.area_code === appliedFilters.country);
    }
    setFilteredPmas(filtered);
    sessionStorage.setItem('admin-pma-filters', JSON.stringify(appliedFilters));
  };

  const handleApplyFilter = () => {
    applyFilters();
  };


  const resetFilters = () => {
    const initialFilters = {
      name: '',
      market: '',
      country: '',
      status: '',
    };

    setFilters(initialFilters);
    sessionStorage.setItem('admin-pma-filters', JSON.stringify(initialFilters));

    applyFilters(initialFilters, pmas);
  };

  return (
    <>
      {!error ? (
        dataLoaded ? (
          pmas && pmas.length !== 0 ? (
            <>
              <div className="filters">
                <p>{t('common-search')}</p>
                <Grid container spacing={3}  rowSpacing={0}>
                  <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                    <div className="form-control">
                      <label htmlFor="name">
                        {t('common-name')}/{t('common-last-name')}
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={filters.name}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </Grid>

                  <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                    <div className="form-control">
                      <label htmlFor="market">{t('common-market')}</label>
                      <select
                        name="market"
                        id="market"
                        className="normal"
                        value={filters.market}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-market')}</option>
                        {markets.map((market) => (
                          <option key={market.name} value={market.name}>
                            {t(`market-${market.name}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>


                  <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                    <div className="form-control">
                      <label htmlFor="country">{t('common-country')}</label>
                      <select
                        name="country"
                        id="country"
                        className="normal"
                        value={filters.country}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-country')}</option>
                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {t(`countries-${country}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                    <div className="actions">
                      <button className="btn" onClick={handleApplyFilter}>
                        {t('common-apply')}
                      </button>
                    </div>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                    <div className="actions">
                      <button onClick={resetFilters} className="btn text narrow">{t('common-reset-filters')}</button>
                    </div>
                  </Grid>
                </Grid>
                <br/>
                <br/>

                <div className="summary">
                  <strong>
                    {t('common-total-results')}: <span>{filteredPmas.length}</span>
                  </strong>
                </div>
              </div>

              <div className="table-wrapper">
                {filteredPmas.length !== 0 ? (
                  <Table
                    sx={{minWidth: 650}}
                    aria-label="simple table"
                    className="user-table pma-pma"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left"><span className="text">{t('common-name')}</span></TableCell>
                        <TableCell align="left"><span className="text">{t('common-last-name')}</span></TableCell>
                        <TableCell align="left"><span className="text">{t('common-email')}</span></TableCell>
                        <TableCell align="left"><span className="text">{t('common-country')}</span></TableCell>
                        <TableCell align="left"><span className="text">{t('common-hacs-asigned')}</span></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredPmas.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell align="left"><span className="text">{row.name}</span></TableCell>
                          <TableCell align="left"><span className="text">{row.last_name}</span></TableCell>
                          <TableCell align="left"><span className="text">{row.email}</span></TableCell>
                          <TableCell align="left"><span
                            className="text">{t(`countries-${row.area_code}`)}</span></TableCell>
                          <TableCell align="left"><span className="text">{row.number_hac_assigned}</span></TableCell>
                          <TableCell align="right">
                            <span className="icon-more" onClick={handleClick(row.id)}></span>
                            <Menu
                              className="submenu"
                              anchorEl={anchorEl[row.id]}
                              open={Boolean(anchorEl[row.id])}
                              onClose={handleClose}
                            >
                              <MenuItem
                                onClick={() => navigate(`/pma/edit-pma`, {
                                  state: {
                                    id: row.id,
                                    breadcrumbName: row.name + ' ' + row.last_name
                                  },
                                })}
                              >
                                {t('admin-main-tab2-option1')}
                              </MenuItem>
                              <MenuItem
                                onClick={() => navigate(`/pma/delete-user`, {
                                  state: {
                                    user: row,
                                    role: 'pma',
                                    breadcrumbName: row.name + ' ' + row.last_name
                                  },
                                })}
                              >
                                {t('admin-main-tab2-option3')}
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div className="no-result">
                    <h4 className="txt-center">{t('admin-main-title-empty')}</h4>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="no-result">
              <h2 className="txt-center">
                {t('admin-main-tab2-title-empty')}
                <small>{t('admin-main-tab2-text-empty')}</small>
              </h2>
            </div>
          )
        ) : (
          <div>{t('common-loading')}</div>
        )
      ) : null}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{width: '100%'}}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default TablePma;
