import React from 'react';
import './footer.scss';
import {useTranslation} from 'react-i18next';

function Footer() {

  const {t} = useTranslation();
  const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

  console.log(t('legal-print-title'));
  console.log(typeof t('legal-print-title'));
  return (
    <footer>
      <section>
        <div className="inner">

            {loggedUser && loggedUser.preferredLanguage === 'en' ? (
              <>
                <ul>
                  { t(`legal-print-text-${loggedUser.preferredLanguage}-${loggedUser.area_code}`) !== "" && (
                    <li><a
                      href="/hac/legal/print">{t(`legal-print-title-${loggedUser.preferredLanguage}-${loggedUser.area_code}`)}</a>
                    </li>
                  )}

                  <li><a
                    href="/hac/legal/privacy">{t(`legal-privacy-title-${loggedUser.preferredLanguage}-${loggedUser.area_code}`)}</a>
                  </li>
                  <li><a
                    href="/hac/legal/terms">{t(`legal-terms-title-${loggedUser.preferredLanguage}-${loggedUser.area_code}`)}</a>
                  </li>
                </ul>
                <p>{t(`legal-footer-${loggedUser.preferredLanguage}-${loggedUser.area_code}`)}</p>
              </>
            ) : (
              <>
                <ul>
                  { t('legal-print-title') !== '' && (
                    <li><a
                      href="/hac/legal/print">{t('legal-print-title')}</a>
                    </li>
                  )}
                  <li><a
                    href="/hac/legal/privacy">{t('legal-privacy-title')}</a>
                  </li>
                  <li><a
                    href="/hac/legal/terms">{t('legal-terms-title')}</a>
                  </li>
                </ul>
                <p>{t('legal-footer')}</p>
              </>
            )}


        </div>
      </section>
    </footer>
);
}

export default Footer;
