import React from 'react';
import './hac-profile.scss';
import { Navigate } from 'react-router-dom';
import decodeToken from '../../../../utils/decode-token';
import EditHacProfile from '../../../organisms/common/hac-profile/org-hac-profile.js';
import { ROLES } from '../../../../constants/constants.js';

const HacProfile = () => {
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  return (
    <>
      {token &&
      (role === ROLES.Pma ||
        role === ROLES.PmaGM ||
        role === ROLES.Admin ||
        role === ROLES.CountryAdmin ||
        role === ROLES.MarketAdmin ||
        role === ROLES.Retailer ||
        role === ROLES.RetailerGM) ? (
        <main>
          <EditHacProfile />
        </main>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default HacProfile;
