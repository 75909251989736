import './unenroll.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import { getHacData } from '../../../../api/hacApi.js';
import { ROLES } from '../../../../constants/constants';
import ModalWindow from '../../../organisms/common/modal/modal';
import { PmaUnenrrollHac } from '../../../../api/pmaApi';
import { Alert } from '@mui/material';

function Unenroll() {
  const { t } = useTranslation();
  const { hacId } = useParams();

  const [hacData, setHacData] = useState(null);
  const [textareaContent, setTextareaContent] = useState('');
  const [textareaError, setTextareaError] = useState(false);

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  const userToDelete = location.state?.user || '';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchHacData = async () => {
      try {
        const response = await getHacData(token, hacId);
        setHacData(response.data);
        console.log(response);
      } catch (err) {}
    };

    if (hacId) {
      fetchHacData();
    }
  }, [hacId, token]);

  const handleTextareaChange = (event) => {
    setTextareaContent(event.target.value);
    if (event.target.value.trim() !== '') {
      setTextareaError(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const unenroll = async () => {
    try {
      const payload = {
        reason: textareaContent,
      };
      await PmaUnenrrollHac(token, hacId, payload);
      closeModal();
      setOpenSnackbar(true);

      setSnackbarMessage(t('pma-unenroll-feedback-success'));
      setSnackbarSeverity('success');

    } catch (err) {
      if (err.response && err.response.status === 409) {
        setOpenErrorModal(true);
      } else {
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    }
  };
  return (
    <>
      {token && (role === ROLES.Pma || role === ROLES.PmaGM) ? (
        <div className="App">
          <Header />
          <main className="unenroll">
            <section className="unenroll">
              <div className="inner">
                <Link to="/pma/main/" className="back-button">
                  <span className="icon-chevron-left"></span> {t('common-back')}{' '}
                  / {breadcrumbName}
                </Link>
                <h2>{t('pma-unenroll-title')}</h2>
                <p>{t('pma-unenroll-text')}</p>
                <div className="data">
                  <strong>
                    {hacData
                      ? `${hacData.name} ${hacData.last_name}`
                      : 'Nombre Apellido'}
                  </strong>
                  <span>{hacData ? hacData.email : 'email@example.com'}</span>
                </div>
                <div
                  className={`form-control required ${
                    textareaError ? 'error' : ''
                  }`}
                >
                  <label>{t('pma-unenroll-label')}</label>
                  <textarea
                    className="txt-area"
                    onChange={handleTextareaChange}
                  ></textarea>
                  {textareaError && (
                    <span className="error-message">
                      {t('pma-unenroll-error')}
                    </span>
                  )}
                </div>
                <p>{t('pma-unenroll-warning')}</p>
                <div className="actions">
                  <button className="btn fit" onClick={openModal}>
                    {t('pma-unenroll-action')}
                  </button>
                </div>
              </div>
            </section>
          </main>
          <ModalWindow
            open={isModalOpen}
            handleClose={closeModal}
            entityId={userToDelete}
            userId={hacId}
            postExecuteFunction={''}
            executeFunction={unenroll}
            title={t('pma-unenroll-title')}
            text={t('pma-unenroll-text') + ':  '}
            extraInfo={hacData ? [`${hacData.name} ${hacData.last_name}`] : ''}
            textButtonCancel={t('common-cancel')}
            textoButtonConfirm={t('admin-delete-modal-action')}
          />

          <ModalWindow
            open={openErrorModal}
            handleClose={handleCloseSnackbar}
            entityId={userToDelete.id}
            executeFunction={''}
            title={t('admin-delete-error-hacs-title')}
            text={t('admin-delete-error-hacs-text')}
            textoButtonConfirm={t('common-close')}
          />
        </div>
      ) : (
        <Navigate to="/login" />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Unenroll;
