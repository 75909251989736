import './vehicles.scss';
import Header from '../../../organisms/header/header.js';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import OrgVehicles from '../../../organisms/common/hac-vehicles/org-vehicles';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { ROLES } from '../../../../constants/constants';

function VehiclesList() {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || '';
  const role = decodeToken(token).role;

  const location = useLocation();
  const { breadcrumbName } = location.state || {};
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (location.state?.openSnackbar) {
      setOpenSnackbar(true);
    }
  }, [location.state]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      {token &&
      (role === ROLES.Pma ||
        role === ROLES.PmaGM ||
        role === ROLES.Admin ||
        role === ROLES.MarketAdmin ||
        role === ROLES.CountryAdmin ||
        role === ROLES.Retailer ||
        role === ROLES.RetailerGM) ? (
        <div className="App">
          <Header />
          <main>
            <section className="vehicles">
              <div className="inner">
                <Link
                  to={`/${
                    role === ROLES.Admin ||
                    role === ROLES.CountryAdmin ||
                    role === ROLES.MarketAdmin
                      ? `${ROLES.Admin}/main`
                      : role === ROLES.Retailer ||
                        role === ROLES.RetailerGM
                      ? `${ROLES.Retailer}/main/active`
                      : role === ROLES.Pma || role === ROLES.PmaGM
                      ? `${ROLES.Pma}/main`
                      : `${role}/main`
                  }`}
                  className="back-button"
                >
                  <span className="icon-chevron-left"></span> {t('common-back')}{' '}
                  / {breadcrumbName}{' '}
                </Link>

                <h4>{t('hac-my-profile-vehicles-title')}</h4>
                <p>{t('hac-my-profile-vehicles-text')}</p>
                <OrgVehicles breadcrumbName={breadcrumbName} />
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleSnackbarClose}
                >
                  <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    sx={{ width: '100%' }}
                  >
                    {t('alert-data-saved')}
                  </Alert>
                </Snackbar>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default VehiclesList;
