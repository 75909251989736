import './table-admin.scss';
import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid2';
import {useTranslation} from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import decodeToken from '../../../../utils/decode-token';
import {Alert, Snackbar, TableSortLabel} from '@mui/material';
import {useNavigate} from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function TableAdmin({reload, onDataLoaded}) {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const userId = decodeToken(token).id;

  const [dataLoaded, setDataLoaded] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [countries, setCountries] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    country: '',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  // Estado de orden
  const [order, setOrder] = useState(''); // Orden ascendente por defecto
  const [orderBy, setOrderBy] = useState(''); // Columna por defecto a ordenar

  // Función de manejo del orden
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortData = (array) => {
    return array.sort((a, b) => {
      if (orderBy === 'name') {
        return order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      if (orderBy === 'last_name') {
        return order === 'asc'
          ? a.last_name.localeCompare(b.last_name)
          : b.last_name.localeCompare(a.last_name);
      }
      if (orderBy === 'last_update') {
        return order === 'asc'
          ? new Date(a.last_update) - new Date(b.last_update)
          : new Date(b.last_update) - new Date(a.last_update);
      }
      return 0;
    });
  };


  /* Menu */
  const [anchorEl, setAnchorEl] = useState({});
  const handleClick = (id) => (event) => {
    setAnchorEl({...anchorEl, [id]: event.currentTarget});
  };
  const handleClose = () => {
    setAnchorEl({});
  };
  const navigate = useNavigate();

  const fetchData = async () => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/admin/admins`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Cache-Control': 'no-cache',
      },
    };

    try {
      const response = await apiClient.get(url, config);
      if (response.status === 200) {
        const data = response.data;
        // Obtener los códigos de área únicos
        const uniqueCountries = [...new Set(response.data.map(admin => admin.area_code).filter(Boolean))];
        setCountries(orderCountries(uniqueCountries));
        const storedFilters = JSON.parse(sessionStorage.getItem('admin-admin-filters') || '{}');
        const initialFilters = {
          ...filters,
          ...storedFilters,
        };
        setFilters(initialFilters);
        setAdmins(data);
        applyFilters(initialFilters, data);
        setDataLoaded(true);
        onDataLoaded();

      } else if (response.status === 204) {
        setDataLoaded(true);
      }
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'));
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [token, userId]);

  useEffect(() => {
    if (reload) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [reload]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const applyFilters = (appliedFilters = filters, adminsData = admins) => {
    let filtered = [...adminsData];

    if (appliedFilters.name) {
      filtered = filtered.filter(
        (admin) =>
          admin.name.toLowerCase().includes(appliedFilters.name.toLowerCase()) ||
          admin.last_name.toLowerCase().includes(appliedFilters.name.toLowerCase())
      );
    }

    if (appliedFilters.country) {
      filtered = filtered.filter((admin) => admin.area_code === appliedFilters.country);
    }

    setFilteredAdmins(filtered);
    sessionStorage.setItem('admin-admin-filters', JSON.stringify(appliedFilters));
  };

  const handleApplyFilter = () => {
    applyFilters(filters, admins);
  };

  const resetFilters = () => {
    const initialFilters = {
      name: '',
      market: '',
      country: '',
      status: '',
    };

    setFilters(initialFilters);
    sessionStorage.setItem('admin-pma-filters', JSON.stringify(initialFilters));

    applyFilters(initialFilters, admins);
  };

  const orderCountries = (countries) => {
    return countries
      .map(country => ({ [country]: t(`countries-${country}`) }))
      .sort((a, b) => Object.values(a)[0].localeCompare(Object.values(b)[0]));
  };

  return (
    <>
      {dataLoaded ? (
        admins && admins.length > 0 ? (
          <>
            <div className="filters">
              <p>{t('common-search')}</p>
              <Grid container spacing={3}>
                <Grid size={{xs: 12, sm: 6, md: 3}}>
                  <div className="form-control">
                    <label htmlFor="name">
                      {t('common-name')}/{t('common-last-name')}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={filters.name}
                      onChange={handleFilterChange}
                    />
                  </div>
                </Grid>
                {(role === 'admin' || role === 'marketAdmin') && countries.length > 1 && (
                  <Grid size={{xs: 12, sm: 6, md: 2}}>
                    <div className="form-control">
                      <label htmlFor="country">{t('common-country')}</label>
                      <select
                        name="country"
                        id="country"
                        className="normal"
                        value={filters.country}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-country')}</option>
                        {countries.map((country) => (
                          <option key={Object.keys(country)[0]} value={Object.keys(country)[0]}>
                            {Object.values(country)[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                  <div className="actions">
                    <button className="btn" onClick={handleApplyFilter}>
                      {t('common-apply')}
                    </button>
                  </div>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                  <div className="actions">
                    <button onClick={resetFilters} className="btn text narrow">{t('common-reset-filters')}</button>
                  </div>
                </Grid>
              </Grid>
              <br/>
              <br/>

              <div className="summary">
                <strong>
                  {t('common-total-results')}:{' '}
                  <span>{filteredAdmins.length}</span>
                </strong>
              </div>
            </div>
            <div className="table-wrapper">
              {filteredAdmins.length !== 0 ? (
                <Table aria-label="simple table" className="user-table admin-admins">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === 'name'}
                          direction={orderBy === 'name' ? order : 'asc'}
                          onClick={() => handleRequestSort('name')}
                        >
                          <span className="text">{t('common-name')}</span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === 'last_name'}
                          direction={orderBy === 'last_name' ? order : 'asc'}
                          onClick={() => handleRequestSort('last_name')}
                        >
                          <span className="text">{t('common-last-name')}</span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left"><span className="text">{t('common-country')}</span></TableCell>
                      <TableCell align="left"><span className="text">{t('common-admin-role')}</span></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      sortData(filteredAdmins).map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="left"><span className="text">{row.name}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.last_name}</span></TableCell>
                        <TableCell align="left"><span
                          className="text">{t(`countries-${row.area_code}`)}</span></TableCell>
                        <TableCell align="left"><span className="text">{row.role}</span></TableCell>
                        <TableCell align="right">
                          <span className="icon-more" onClick={handleClick(row.id)}></span>
                          <Menu
                            className="submenu"
                            anchorEl={anchorEl[row.id]}
                            open={Boolean(anchorEl[row.id])}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => navigate(`/admin/edit-admin`, {
                                state: {
                                  id: row.id,
                                  breadcrumbName: row.name + ' ' + row.last_name
                                },
                              })}
                            >
                              {t('admin-edit-admin-title')}
                            </MenuItem>
                            <MenuItem
                              onClick={() => navigate(`/admin/delete-user`, {
                                state: {
                                  user: row
                                },
                              })}
                            >
                              {t('admin-main-tab2-option3')}
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="no-result">
                  <h4 className="txt-center">{t('admin-main-title-empty')}</h4>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('admin-main-tab1-title-empty')}
              <small>{t('admin-main-tab1-text-empty')}</small>
            </h2>
          </div>
        )
      ) : (
        <div>{t('common-loading')}</div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{width: '100%'}}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableAdmin;
