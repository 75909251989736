import { API_METHOD, apiRequest } from '../utils/apiClient';

export const fetchRetailers = (token) =>
  apiRequest(API_METHOD.GET, `/retailer/all/active`, token);

export const inactiveSalePerson = (token, salePersonId) =>
  apiRequest(API_METHOD.POST, `/retailer/inactive-retailer-salesperson/${salePersonId}`, token);

export const inactiveRetailerGM = (token, gmId) =>
  apiRequest(
    API_METHOD.POST,
    `/retailer/inactive-general-manager/${gmId}`,
    token
  );

export const createRetailerGM = (token, retailerGM) =>
  apiRequest(
    API_METHOD.POST,
    `/retailer/retailer-general-manager`, retailerGM, 
    token
);

export const getRetailerManagerByEmail = (token, email) =>
  apiRequest(
    API_METHOD.GET,
    `/retailer/retailer-general-manager/${email}`,
    token
);

export const getRetailerById = (token, retailerId) =>
  apiRequest(API_METHOD.GET, `/retailer?retailerId=${retailerId}`, token);




     

       
