import './delete-user.scss';
import Header from '../../../organisms/header/header.js';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import Grid from '@mui/material/Grid2';
import { ROLES } from '../../../../constants/constants';
import ModalWindow from '../../../organisms/common/modal/modal';
import { inactivePma } from '../../../../api/pmaApi';
import { Alert } from '@mui/material';
import {
  inactiveSalePerson,
  inactiveRetailerGM,
} from '../../../../api/retailerApi';
import { inactiveAdmin } from '../../../../api/adminApi';

function DeleteUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  const userToDelete = location.state?.user || '';
  const userToDeleteRole = location.state?.role || userToDelete.role || '';
  const userToDeleteRetailerName = location.state?.retailer_name || '';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const deleteUser = async (token, hacId, entityId) => {
    if (!userToDeleteRole) return;
    try {
      let response;
      switch (userToDeleteRole) {
        case ROLES.Pma:
        case ROLES.PmaGM:
          response = await inactivePma(token, entityId);
          break;
        case ROLES.Retailer:
          response = await inactiveSalePerson(token, entityId);
          break;
        case ROLES.RetailerGM:
          response = await inactiveRetailerGM(token, entityId);
          break;
        case ROLES.Admin:
        case ROLES.CountryAdmin:
        case ROLES.MarketAdmin:
        case ROLES.ShippingAdmin:
          response = await inactiveAdmin(token, entityId);
          break;
        default:
          return;
      }

      if (response.status === 200) {
        setOpenSnackbar(true);
        setSnackbarMessage(t('admin-delete-feedback-ok'));
        setSnackbarSeverity('success');
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setOpenErrorModal(true);
      } else {
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    }
  };

  return (
    <>
      {token && role === 'pmaGM' ? (
        <div className="App">
          <Header />
          <main className="delete-user">
            <section className="delete-user">
              <div className="inner">
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="back-button"
                >
                  <span className="icon-chevron-left"></span> {t('common-back')}{' '}
                  / {breadcrumbName}
                </Link>
                <h2>{t('admin-delete-user-title')}</h2>
                <p>
                  {t('admin-delete-user-text', {
                    userToDeleteRole: t(`common-role-${userToDeleteRole}`),
                  })}
                </p>
                <div className="data">
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 4, sm: 4, md: 6 }}>
                      <span>{t('common-name')}</span>
                      <strong>
                        {userToDelete.name} {userToDelete.last_name}{' '}
                        {userToDeleteRole}
                      </strong>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 6 }}>
                      {(userToDeleteRole === ROLES.Retailer ||
                        userToDeleteRole === ROLES.RetailerGM ) && (
                        <>
                          <span>{t('common-retailer')}</span>
                          <strong>{userToDeleteRetailerName}</strong>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 4, sm: 4, md: 6 }}>
                      <span>{t('common-email')}</span>
                      <strong>{userToDelete.email}</strong>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 6 }}>
                      <span>{t('common-phone')}</span>
                      <strong>
                        {userToDelete.prefix_phone} {userToDelete.phone}
                      </strong>
                    </Grid>
                  </Grid>
                </div>
                <p>
                  <strong>{t('admin-delete-warning')}</strong>
                </p>

                <div className="actions">
                  <button className="btn fit" onClick={openModal}>
                    {t('admin-delete-user-action')}
                  </button>
                </div>
              </div>
            </section>
          </main>

          <ModalWindow
            open={isModalOpen}
            handleClose={closeModal}
            entityId={userToDelete.id}
            userId={''}
            postExecuteFunction={''}
            executeFunction={deleteUser}
            title={t('admin-delete-modal-title')}
            text={t('admin-delete-modal-text')}
            textButtonCancel={t('common-cancel')}
            textoButtonConfirm={t('admin-delete-modal-action')}
          />

          <ModalWindow
            open={openErrorModal}
            handleClose={() => navigate(-1)}
            entityId={userToDelete.id}
            executeFunction={() => navigate(-1)}
            title={t('admin-delete-error-hacs-title')}
            text={t('admin-delete-error-hacs-text')}
            textoButtonConfirm={t('common-close')}
          />
        </div>
      ) : (
        <Navigate to="/login" />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DeleteUser;
