import './preferences-hac.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { Collapse } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ModalHacEditPreferences from '../modal-hac-edit-preferences/modal-hac-edit-preferences';
import { useTranslation } from 'react-i18next';
import { getPreferencesByHacAndCategory } from '../../../../api/hacApi.js';
import decodeToken from '../../../../utils/decode-token';
import { Alert, Snackbar } from '@mui/material';
import ModalWindow from '../../common/modal/modal';
import { deletePreference } from '../../../../api/hacApi.js';


export default function PreferencesHac({ cat }) {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const hacId = decodeToken(token).id;

  const [expandedItem, setExpandedItem] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState({
    title: '',
    value: '',
  });
  const [preferenceIdToDelete, setPreferenceIdToDelete] = useState(null);

  const [preferences, setPreferences] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchData = useCallback(async () => {
    setSelectedPreference({});
    try {
      const response = await getPreferencesByHacAndCategory(token, hacId, cat);
      const data = response.data;
      setPreferences(data.length > 0 ? sortDataByTitle(data) : []);
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    }
  }, [hacId, cat, token, t]);

  function sortDataByTitle(data) {
    return data.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();

      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    });
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExpand = (itemName) => {
    setExpandedItem((prevItem) => (prevItem === itemName ? null : itemName));
  };

  const handleClick = (event, index, item) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
    setSelectedPreference(item);
  };

  const handleClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setSelectedPreference({ title: '', value: '' });
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setPreferenceIdToDelete(null);
  };

  const open = (index) => Boolean(anchorEl[index]);

  const handleDeletePreference = async (preferenceId) => {
     setPreferenceIdToDelete(preferenceId);
     setDeleteModalOpen(true);
     try {
       const token = sessionStorage.getItem('token') || null;
       const hacId = decodeToken(token).id;

       await deletePreference(token, preferenceId, hacId);
   
     } catch (error) {
       console.error('Error al eliminar la preferencia:', error);
     }
   };

  return (
    <>
      <div className="actions">
        <button
          className="btn text"
          onClick={() => {
            setEditModalOpen(true);
          }}
        >
          {' '}
          <span className="icon-plus-circle"></span> {t('hac-preferences-add')}
        </button>
      </div>
      {preferences.length > 0 ? (
        <ul className="hac-preferences">
          {preferences.map((item, index) => (
            <li key={index}>
              <span>
                <span onClick={() => handleExpand(`preferences${index}`)}>
                  {expandedItem === `preferences${index}` ? (
                    <span className="icon-chevron-up"></span>
                  ) : (
                    <span className="icon-chevron-down"></span>
                  )}{' '}
                  {item.title}
                </span>
                <span
                  aria-controls={open(index) ? `menu${index}` : undefined}
                  aria-haspopup="true"
                  aria-expanded={open(index) ? 'true' : undefined}
                  onClick={(event) => handleClick(event, index, item)}
                >
                  <span className="icon-more"></span>
                </span>
              </span>
              <Collapse in={expandedItem === `preferences${index}`}>
                <p>{item.value} </p>
              </Collapse>
              <Menu
                className="submenu"
                id={`menu${index}`}
                anchorEl={anchorEl[index]}
                open={open(index)}
                onClose={() => handleClose(index)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose(index);
                    setEditModalOpen(true);
                  }}
                >
                  <span className="icon-edit"></span>{' '}
                  {t('hac-preferences-menu1')}
                </MenuItem>
                <MenuItem onClick={() => handleDeletePreference(item.id)}>
                  <span className="icon-delete"></span>{' '}
                  {t('hac-preferences-menu2')}
                </MenuItem>
              </Menu>
            </li>
          ))}
        </ul>
      ) : (
        <h4>{t('hac-preferences-no-data')}</h4>
      )}

      <ModalHacEditPreferences
        className="confirm"
        open={editModalOpen}
        handleClose={handleEditModalClose}
        selectedPreference={selectedPreference}
        cat={cat}
        onSave={fetchData} // Pasa fetchData como onSave
      />

      <ModalWindow
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        entityId={preferenceIdToDelete}
        userId={hacId}
        postExecuteFunction={fetchData}
        executeFunction={handleDeletePreference}
        title={t('hac-preferences-delete-title')}
        text={t('hac-preferences-delete-text')}
        feedback={t('hac-preferences-delete-feedback')}
        textButtonCancel={t('common-cancel')}
        textoButtonConfirm={t('common-remove')}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
