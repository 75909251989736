import './modal-unassign-retailerGM.scss';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 518,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalUnassignRetailerGM({ open, onClose, generalGMData, retailerId }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  // Estado para los detalles del GM obtenidos de la API
  const [gmDetails, setGmDetails] = useState(null);

  // Llamada a la API cuando se abre la modal
  useEffect(() => {
    if (generalGMData && generalGMData.email) {
      const fetchGMData = async () => {
        try {
          const response = await apiClient.get(
            `${process.env.REACT_APP_API_URL}/retailer/retailer-general-manager/${generalGMData.email}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              }
            }
          );
          if (response.status === 200) {
            setGmDetails(response.data);
          }
        } catch (error) {
/*          console.error('Error fetching GM data:', error);
          setSnackbarMessage(t('common-internal-server-error'));
          setSnackbarSeverity('error');
          setSnackbarOpen(true);*/
        }
      };
      fetchGMData();
    }
  }, [generalGMData, token, t]);

  const onSubmit = async () => {
    // Filtrar los retailers excluyendo el que coincide con retailerId
    const filteredRetailers = gmDetails.retailerInfo.filter(
      (retailer) => retailer.id !== parseInt(retailerId) // Excluir el retailer con id igual al retailerId
    );

    // Construir la cadena de retailer_codes, con "LRD1033" como el primer valor y luego los demás retailer_codes separados por comas
    const retailerCodes = filteredRetailers.map((retailer) => retailer.retailer_code).join(',');

    const payload = {
      ids_retailer: `${retailerCodes}` // Concatenamos "LRD1033" con el resto de los retailer_codes
    };

    try {
      const response = await apiClient.patch(
        `${process.env.REACT_APP_API_URL}/retailer/retailer-general-manager-rel/${generalGMData.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200) {
        setSnackbarMessage(t('admin-unassign-retailergm-ok'));
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

    } catch (error) {

      setSnackbarMessage(t('common-internal-server-error'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose} className="modal-unassign-retailerGM">
        <Box sx={style}>
          <button
            className="close"
            onClick={handleClose}
          >
            <span className="icon-close"></span>
          </button>
          <h2>{t('admin-unassign-retailergm-title')}</h2>
          <p>{t('admin-unassign-retailergm-text')}</p>

          {/* Mostrar los detalles del GM si los datos fueron obtenidos */}
          {gmDetails ? (
            <>
              <p>{gmDetails.name} {gmDetails.last_name}</p>
              <p>{gmDetails.email}</p>
            </>
          ) : (
            <p>{t('common-loading')}</p> // Mensaje mientras cargan los datos
          )}

          <div className="actions">
            <button className="btn secondary" onClick={handleClose}>
              {t('common-cancel')}
            </button>
            <button className="btn" onClick={onSubmit}>
              {t('admin-unassign-retailergm-action')}
            </button>
          </div>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalUnassignRetailerGM;
