import './org-hac-profile.scss';
import React, { useEffect, useState } from 'react';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import Header from '../../header/header.js';
import decodeToken from '../../../../utils/decode-token.js';
import Grid from '@mui/material/Grid2';

import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';
import Input from '../../../atoms/input.js';
import Snackbar from '@mui/material/Snackbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import { Alert, Collapse } from '@mui/material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  getAcademicTitles,
  getCountries,
  getHacData,
  updateHac,
  deleteAddress,
} from '../../../../api/hacApi.js';
import { ROLES, HAC_STATUS } from '../../../../constants/constants.js';
import ModalResendNomination from '../../../organisms/role-retailer/modal-resend-nomination/modal-resend-nomination';

const setValues = (values, setterFn) => {
  const requiredValues = [
    'name',
    'email',
    'middle_name',
    'last_name',
    'phone',
    'preferred_address',
    'prefix_phone',
    'title',
    'academic_title',
    'birth_day',
    'birth_day_partner',
    'pma_notes',
    'name_partner',
    'last_name_partner',
  ];
  for (const [key, value] of Object.entries(values)) {
    if (requiredValues.includes(key) && value) {
      setterFn(key, value);
    }
  }
};

function EditHacProfile() {
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const { t } = useTranslation();

  const { hacId } = useParams();

  const [countries, setCountries] = useState([]);
  const [areaCode, setAreaCode] = useState('');
  const [academicTitles, setAcademicTitles] = useState([]);
  const [hacData, setHacData] = useState({});
  const [profileStatus, setProfileStatus] = useState(HAC_STATUS.Nominated);

  const [phoneError, setPhoneError] = useState('');
  const [resendModalOpen, setResendModalOpen] = useState(false);

  const handleOpenResendModal = (event) => {
    event.preventDefault();
    setResendModalOpen(true);
  };

  const handleCloseResendModal = (event) => {
    setResendModalOpen(false);
  };

  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  const {
    formState,
    register,
    handleSubmit,
    setValue,
    watch,
    resetField,
    setFocus,
    control,
  } = useForm();

  const [preferredAddress, setPreferredAddress] = useState('');

  const privateAddress = watch('private_address');
  const companyAddress = watch('company_address');
  const [expandedSections, setExpandedSections] = useState({
    basic: true,
    address: true,
  });

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  const { errors } = formState;

  useEffect(() => {
    const fetchAcademicTitles = async () => {
      if (areaCode) {
        try {
          const response = await getAcademicTitles(token, areaCode);
          setAcademicTitles(response.data);
        } catch (error) {
          if (error.response) {
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage(t('common-internal-server-error'), error);
          }
        }
      }
    };

    fetchAcademicTitles();
  }, [token, t, areaCode]);

  useEffect(() => {
    if (hacData.academic_title) {
      setValue('academic_title', hacData.academic_title);
    }
  });

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await getCountries();
      setCountries(countries.data);
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getHacData(token, hacId);

        setProfileStatus(response.data.status);
        if (response.status !== 200) throw new Error('Error getting hac data');
        setProfileStatus(response.data.status);
        setPreferredAddress(response.data.preferred_address);
        setValues(response.data, setValue);
        setAreaCode(response.data.area_code);
        setHacData(response.data);

        const privateAddr = response.data.address.find(
          (address) => address.address_type === 'private'
        );
        const companyAddr = response.data.address.find(
          (address) => address.address_type === 'company'
        );
        if (companyAddr) {
          setValue('company_address', companyAddr);
        }
        if (privateAddr) {
          setValue('private_address', privateAddr);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      }
    };
    fetchData();
  }, [t, token, hacId, setValue]);

  const handlePreferredAddressChange = (event) => {
    setPreferredAddress(event.target.value);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClearAddress = async (type) => {
    const address = type === 'private' ? privateAddress : companyAddress;

    try {
      await deleteAddress(token, address.hac, address.id);
      Object.keys(address).forEach((key) => {
        address[key] = undefined;
        resetField(`${type}_address.${key}`);
      });

      setSnackbarMessage(t('alert-address-deleted'));
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage(
        error.response?.status === 409
          ? t('alert-address-delete-preferred')
          : t('common-internal-server-error')
      );
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const onSubmit = handleSubmit(
    async ({
      private_address,
      company_address,
      middle_name,
      birth_day,
      birth_day_partner,
      name_partner,
      last_name_partner,
      ...rest
    }) => {
      if (Object.keys(errors).length) return;
      let payload = {
        ...rest,
        middle_name: middle_name.length ? middle_name : undefined,
        name_partner: name_partner
          ? name_partner.length
            ? name_partner
            : undefined
          : undefined,
        last_name_partner: last_name_partner
          ? last_name_partner.length
            ? last_name_partner
            : undefined
          : undefined,
        birth_day: birth_day ? birth_day : undefined,
        birth_day_partner: birth_day_partner ? birth_day_partner : undefined,
        preferred_address: preferredAddress ? preferredAddress : undefined,
        address: [],
      };

      if (private_address?.alias) {
        payload.address.push({
          id: private_address.id,
          alias: private_address?.alias,
          area_code: private_address.area_code,
          address_type: 'private',
          address_line_1: private_address.address_line_1,
          address_number: private_address.address_number,
          city: private_address.city,
          postal_code: private_address.postal_code,
          address_line_2: private_address.address_line_2
            ? private_address.address_line_2
            : undefined,
        });
      }

      if (company_address?.company_name) {
        payload.address.push({
          id: company_address.id,
          company_name: company_address.company_name,
          area_code: company_address.area_code,
          address_type: 'company',
          address_line_1: company_address.address_line_1,
          address_number: company_address.address_number,
          city: company_address.city,
          postal_code: company_address.postal_code,
          address_line_2: company_address.address_line_2
            ? company_address.address_line_2
            : undefined,
        });
      }

      if (payload.address.length === 0) {
        delete payload.address;
      }

      try {
        const fullPhoneNumber = parsePhoneNumberFromString(
          payload.prefix_phone + payload.phone
        );

        if (!fullPhoneNumber.isValid()) {
          setPhoneError(t('alert-phone-format'));
          setFocus('phone');
          return;
        } else {
          setPhoneError('');

          await updateHac(token, hacId, JSON.parse(JSON.stringify(payload)));

          setSnackbarMessage(t('alert-data-saved'));
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 413) {
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage(t('alert-address-too-large'));
          } else {
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
            setSnackbarMessage(t('common-internal-server-error'), error);
          }
        } else {
          setSnackbarOpen(true);
          setSnackbarSeverity('error');
          setSnackbarMessage(error);
        }
      }
    }
  );

  const validateAddressField = (field, allFields) => {
    const privateAddress = allFields.private_address;
    const isPrivateAddressSelected = preferredAddress === 'private';

    const isAnyFieldFilled = Object.values(privateAddress).some(
      (val) => val !== undefined && val !== ''
    );

    if (isAnyFieldFilled || isPrivateAddressSelected) {
      if (!privateAddress[field]) {
        return t('alert-address-must-complete');
      }
    }
    return true;
  };

  const validateCompanyField = (field, allFields) => {
    const companyAddress = allFields.company_address;
    const isCompanyAddressSelected = preferredAddress === 'company';

    const isAnyFieldFilled = Object.values(companyAddress).some(
      (val) => val !== undefined && val !== ''
    );

    if (isAnyFieldFilled || isCompanyAddressSelected) {
      if (!companyAddress[field]) {
        return t('alert-address-must-complete');
      }
    }
    return true;
  };

  return (
    <>
      {token &&
      (role === ROLES.Pma ||
        role === ROLES.PmaGM ||
        role === ROLES.Admin ||
        role === ROLES.CountryAdmin ||
        role === ROLES.MarketAdmin ||
        role === ROLES.Retailer ||
        role === ROLES.RetailerGM) ? (
        <div className="App">
          <Header />
          <main>
            <section className="form">
              <div className="inner">
                <form onSubmit={onSubmit}>
                  <Link
                    to={`/${
                      role === ROLES.Admin ||
                      role === ROLES.CountryAdmin ||
                      role === ROLES.MarketAdmin
                        ? 'admin/main'
                        : role === ROLES.Retailer || role === ROLES.RetailerGM
                        ? `retailer/main/${profileStatus}`
                        : role === ROLES.Pma || role === ROLES.PmaGM
                        ? 'pma/main'
                        : `${role}/main`
                    }`}
                    className="back-button"
                  >
                    <span className="icon-chevron-left"></span>{' '}
                    {t('common-back')} / {breadcrumbName}
                  </Link>
                  <h2>{t('pma-profile-title')}</h2>

                  {profileStatus === HAC_STATUS.Active ||
                  (role !== ROLES.Retailer && role !== ROLES.RetailerGM) ||
                  (profileStatus !== HAC_STATUS.Nominated &&
                    profileStatus !== HAC_STATUS.Exited) ? (
                    <div
                      className="title"
                      onClick={() => toggleSection('basic')}
                    >
                      <h3>{t('common-basic-info')}</h3>

                      <span className="btn text">
                        {expandedSections.basic ? (
                          <span className="icon-minus"></span>
                        ) : (
                          <span className="icon-plus"></span>
                        )}
                      </span>
                    </div>
                  ) : (
                    <div className="title">
                      <h3>{t('common-basic-info')}</h3>
                    </div>
                  )}
                  <Collapse in={expandedSections.basic}>
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 6, sm: 4, md: 2 }}>
                        <div className="form-control">
                          <label htmlFor="title">{t('common-title')}</label>
                          <select
                            name="title"
                            id="title"
                            className="normal"
                            disabled={
                              profileStatus === HAC_STATUS.Exited ||
                              profileStatus === HAC_STATUS.Nominated
                            }
                            {...register('title')}
                          >
                            <option value="Mr">{t('common-title-Mr')}</option>
                            <option value="Mrs">{t('common-title-Mrs')}</option>
                            <option value="Mx">{t('common-title-Mx')}</option>
                          </select>
                        </div>
                      </Grid>
                      {academicTitles.length !== 0 && (
                        <Grid size={{ xs: 6, sm: 4, md: 4 }}>
                          <div className="form-control">
                            <label htmlFor="academic-title">
                              {t('common-academic-title')}
                            </label>
                            <select
                              name="academic-title"
                              id="academic-title"
                              className="normal"
                              {...register('academic_title')}
                              disabled={
                                profileStatus === HAC_STATUS.Exited ||
                                profileStatus === HAC_STATUS.Nominated
                              }
                            >
                              <option key="" value="">
                                {t('common-select-academic-title')}
                              </option>
                              {Array.isArray(academicTitles) &&
                                academicTitles.map((academicTitle) => (
                                  <option
                                    key={academicTitle.sv_value}
                                    value={academicTitle.sv_value}
                                  >
                                    {academicTitle.text}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Grid>
                      )}
                      <Grid size={{ xs: 12, sm: 8, md: 5 }}>
                        <div className="form-control required">
                          <Input
                            type="text"
                            id="name"
                            label={t('common-first-name')}
                            maxLength="50"
                            error={errors?.name}
                            disabled={
                              profileStatus === HAC_STATUS.Exited ||
                              profileStatus === HAC_STATUS.Nominated
                            }
                            {...register('name', {
                              validate: (v) => {
                                const { error } = Joi.string()
                                  .required()
                                  .error(() => new Error(t('alert-name')))
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                        <div className="form-control">
                          <label htmlFor="middle_name">
                            {t('common-middle-name')}
                          </label>
                          <input
                            maxLength="50"
                            type="text"
                            id="middle_mame"
                            disabled={
                              profileStatus === HAC_STATUS.Exited ||
                              profileStatus === HAC_STATUS.Nominated
                            }
                            {...register('middle_name', {})}
                          />
                        </div>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="last_name"
                            label={t('common-last-name')}
                            error={errors?.last_name}
                            disabled={
                              profileStatus === HAC_STATUS.Exited ||
                              profileStatus === HAC_STATUS.Nominated
                            }
                            {...register('last_name', {
                              validate: (v) => {
                                const { error } = Joi.string()
                                  .required()
                                  .error(() => new Error(t('alert-last-name')))
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 3, md: 4 }}>
                        <div className="form-control datepicker">
                          <label htmlFor="birth_day">
                            {t('common-birthday')}
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              name="birth_day"
                              control={control}
                              render={({ field }) => (
                                <DateField
                                  {...field}
                                  format="DD/MM"
                                  disabled={
                                    profileStatus === HAC_STATUS.Exited ||
                                    profileStatus === HAC_STATUS.Nominated
                                  }
                                  onChange={(date) => {
                                    const isValidDate = dayjs(date).isValid();
                                    if (isValidDate) {
                                      const formattedDate =
                                        dayjs(date).format('MM/DD');
                                      field.onChange(formattedDate);
                                    } else {
                                      field.onChange('');
                                    }
                                  }}
                                  value={
                                    field.value
                                      ? dayjs(field.value, 'MM/DD')
                                      : null
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <div className="form-control">
                          <label htmlFor="email">{t('common-email')}</label>
                          <input
                            maxLength="50"
                            type="text"
                            id="email"
                            {...register('email', {
                              validate: (v) =>
                                Joi.string()
                                  .email({ tlds: { allow: false } })
                                  .required()
                                  .validate(v),
                            })}
                            readOnly
                          />
                        </div>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <div>
                          <Grid container spacing={1}>
                            <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  id="prefix_phonee"
                                  label={t('common-prefix')}
                                  placeholder="+xx/+xxx"
                                  disabled={
                                    profileStatus === HAC_STATUS.Exited ||
                                    profileStatus === HAC_STATUS.Nominated
                                  }
                                  error={errors?.prefix_phone}
                                  {...register('prefix_phone', {
                                    validate: (v) => {
                                      const { error } = Joi.string()
                                        .regex(/^\+[0-9]{1,3}$/)
                                        .required()
                                        .error(
                                          () => new Error(t('alert-prefix'))
                                        )
                                        .validate(v);
                                      return error?.message
                                        ? error.message
                                        : true;
                                    },
                                  })}
                                />
                              </div>
                            </Grid>
                            <Grid size={{ xs: 8, sm: 8, md: 8 }}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  id="phone"
                                  label={t('common-phone')}
                                  disabled={
                                    profileStatus === HAC_STATUS.Exited ||
                                    profileStatus === HAC_STATUS.Nominated
                                  }
                                  error={errors?.phone}
                                  {...register('phone', {
                                    validate: (v) => {
                                      const { error } = Joi.string()
                                        .required()
                                        .error(
                                          () => new Error(t('alert-phone'))
                                        )
                                        .validate(v);
                                      return error?.message
                                        ? error.message
                                        : true;
                                    },
                                  })}
                                />
                                {phoneError && (
                                  <p className="error-message">{phoneError}</p>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>

                    {profileStatus === HAC_STATUS.Nominated &&
                      (role === ROLES.Retailer ||
                        role === ROLES.RetailerGM) && (
                        <div className="resend">
                          <h3>{t('retailer-nomination-resend-title')}</h3>
                          <p>{t('retailer-nomination-resend-text')}</p>
                          <button
                            className="btn secondary fit"
                            onClick={(event) => handleOpenResendModal(event)}
                          >
                            {t('retailer-nomination-resend-action')}
                          </button>
                        </div>
                      )}

                    {profileStatus === HAC_STATUS.Active && (
                      <Grid container spacing={3}>
                        <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                          <div className="form-control">
                            <label htmlFor="name_partner">
                              {t('common-name-partner')}
                            </label>
                            <input
                              maxLength="50"
                              type="text"
                              id="name_partner"
                              disabled={
                                profileStatus === HAC_STATUS.Exited ||
                                profileStatus === HAC_STATUS.Nominated
                              }
                              {...register('name_partner', {})}
                            />
                          </div>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                          <div className="form-control">
                            <label htmlFor="last_name_partner">
                              {t('common-last-name-partner')}
                            </label>
                            <input
                              maxLength="50"
                              type="text"
                              id="last_name_partner"
                              disabled={
                                profileStatus === HAC_STATUS.Exited ||
                                profileStatus === HAC_STATUS.Nominated
                              }
                              {...register('last_name_partner', {})}
                            />
                          </div>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3, md: 4 }}>
                          <div className="form-control datepicker">
                            <label htmlFor="birth_day_partner">
                              {t('common-birthday-partner')}
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Controller
                                name="birth_day_partner"
                                control={control}
                                render={({ field }) => (
                                  <DateField
                                    {...field}
                                    format="DD/MM"
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    onChange={(date) => {
                                      const isValidDate = dayjs(date).isValid();
                                      if (isValidDate) {
                                        const formattedDate =
                                          dayjs(date).format('MM/DD');
                                        field.onChange(formattedDate);
                                      } else {
                                        field.onChange('');
                                      }
                                    }}
                                    value={
                                      field.value
                                        ? dayjs(field.value, 'MM/DD')
                                        : null
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    {(role === ROLES.Pma || role === ROLES.PmaGM) && (
                      <Grid container spacing={3}>
                        <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                          <h3>{t('pma-profile-personal-notes-title')}</h3>
                          <p>{t('pma-profile-personal-notes-text')}</p>
                          <textarea
                            id="note"
                            maxLength="20000"
                            disabled={profileStatus === HAC_STATUS.Exited}
                            {...register('pma_notes')}
                            className="txt-area"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Collapse>
                  <br />
                  <br />

                  {((profileStatus === HAC_STATUS.Nominated && privateAddress) ||
                    profileStatus === HAC_STATUS.Active) && (
                    <>
                      <div
                        className="title"
                        onClick={() => toggleSection('address')}
                      >
                        <h3>{t('common-address-information')}</h3>
                        <span className="btn text">
                          {expandedSections.address ? (
                            <span className="icon-minus"></span>
                          ) : (
                            <span className="icon-plus"></span>
                          )}
                        </span>
                      </div>
                      <Collapse in={expandedSections.address}>
                        {profileStatus === HAC_STATUS.Active && (
                          <>
                            <p>{t('retailer-nomination-choose-address')}</p>
                            <div className="form-control">
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={preferredAddress}
                                onChange={handlePreferredAddressChange}
                              >
                                <FormControlLabel
                                  value="private"
                                  name="preferred_address"
                                  control={<Radio />}
                                  label={t('common-private-address')}
                                />
                                <FormControlLabel
                                  value="company"
                                  name="preferred_address"
                                  control={<Radio />}
                                  label={t('common-company-address')}
                                />
                              </RadioGroup>
                            </div>
                          </>
                        )}

                        {((profileStatus === HAC_STATUS.Nominated && privateAddress) ||
                          profileStatus === HAC_STATUS.Active) && (
                          <div className="address">
                            <div className="address-header">
                              <h5>{t('common-private-address')}</h5>
                              {privateAddress?.id && (
                                <button
                                  type="button"
                                  className="btn-form btn text"
                                  onClick={() => {
                                    handleClearAddress('private');
                                  }}
                                >
                                  <span className="icon-delete"></span>
                                </button>
                              )}
                            </div>

                            <div className="form-control">
                              <Input
                                maxLength="50"
                                type="text"
                                id="alias_private"
                                placeholder={t('nominate-alias-placeholder')}
                                label={t('common-alias')}
                                disabled={
                                  profileStatus === HAC_STATUS.Exited ||
                                  profileStatus === HAC_STATUS.Nominated
                                }
                                error={errors?.private_address?.alias}
                                {...register('private_address.alias', {
                                  validate: (value, allValues) =>
                                    validateAddressField('alias', allValues),
                                })}
                              />
                            </div>

                            <Grid container spacing={1}>
                              <Grid size={{ xs: 12, sm: 8, md: 8 }}>
                                <div className="form-control">
                                  <Input
                                    maxLength="150"
                                    type="text"
                                    id="address_line_1_private"
                                    label={t('common-address-name')}
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    error={
                                      errors?.private_address?.address_line_1
                                    }
                                    {...register(
                                      'private_address.address_line_1',
                                      {
                                        validate: (value, allValues) =>
                                          validateAddressField(
                                            'address_line_1',
                                            allValues
                                          ),
                                      }
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <Input
                                    maxLength="5"
                                    type="text"
                                    id="address_number_private"
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    label={t('common-address-number')}
                                    error={
                                      errors?.private_address?.address_number
                                    }
                                    {...register(
                                      'private_address.address_number',
                                      {
                                        validate: (value, allValues) =>
                                          validateAddressField(
                                            'address_number',
                                            allValues
                                          ),
                                      }
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>

                            <div className="form-control">
                              <label htmlFor="address_line_2_private">
                                {t('common-address-line-2')}
                              </label>
                              <input
                                maxLength="150"
                                type="text"
                                disabled={
                                  profileStatus === HAC_STATUS.Exited ||
                                  profileStatus === HAC_STATUS.Nominated
                                }
                                id="address_line_2_private"
                                {...register('private_address.address_line_2')}
                              />
                            </div>

                            <Grid container spacing={3}>
                              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <label htmlFor="country_private">
                                    {t('common-country')}
                                  </label>
                                  <select
                                    name="area_code_private"
                                    id="country_private"
                                    className="normal"
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    error={errors?.private_address?.area_code}
                                    {...register('private_address.area_code', {
                                      validate: (value, allValues) =>
                                        validateAddressField(
                                          'area_code',
                                          allValues
                                        ),
                                    })}
                                  >
                                    <option key="" value="">
                                      {t('common-select-country')}
                                    </option>
                                    {countries.map((country) => (
                                      <option
                                        key={country.area_code}
                                        value={country.area_code}
                                      >
                                        {t('countries-' + country.area_code)}
                                      </option>
                                    ))}
                                  </select>
                                  {errors?.private_address?.area_code && (
                                    <span className="input__error">
                                      {errors.private_address.area_code.message}
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <Input
                                    maxLength="50"
                                    type="text"
                                    id="city_private"
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    label={t('common-city')}
                                    error={errors?.private_address?.city}
                                    {...register('private_address.city', {
                                      validate: (value, allValues) =>
                                        validateAddressField('city', allValues),
                                    })}
                                  />
                                </div>
                              </Grid>
                              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <Input
                                    maxLength="50"
                                    type="text"
                                    id="postal_code_private"
                                    label={t('common-zip-cp')}
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    error={errors?.private_address?.postal_code}
                                    {...register(
                                      'private_address.postal_code',
                                      {
                                        validate: (value, allValues) =>
                                          validateAddressField(
                                            'postal_code',
                                            allValues
                                          ),
                                      }
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        )}

                        {((profileStatus === HAC_STATUS.Nominated && companyAddress) ||
                          profileStatus === HAC_STATUS.Active) && (
                          <div className="address">
                            <div className="address-header">
                              <h5>{t('common-company-address')}</h5>
                              {companyAddress?.id && (
                                <button
                                  type="button"
                                  className="btn-form btn text"
                                  onClick={() => handleClearAddress('company')}
                                >
                                  <span className="icon-delete"></span>{' '}
                                </button>
                              )}
                            </div>

                            <div className="form-control">
                              <Input
                                maxLength="50"
                                type="text"
                                id="company_name"
                                disabled={
                                  profileStatus === HAC_STATUS.Exited ||
                                  profileStatus === HAC_STATUS.Nominated
                                }
                                label={t('common-company-name')}
                                error={errors?.company_address?.company_name}
                                {...register('company_address.company_name', {
                                  validate: (value, allValues) =>
                                    validateCompanyField(
                                      'company_name',
                                      allValues
                                    ),
                                })}
                              />
                            </div>

                            <Grid container spacing={1}>
                              <Grid size={{ xs: 12, sm: 8, md: 8 }}>
                                <div className="form-control">
                                  <Input
                                    maxLength="150"
                                    type="text"
                                    id="address_line_1_company"
                                    label={t('common-address-name')}
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    error={
                                      errors?.company_address?.address_line_1
                                    }
                                    {...register(
                                      'company_address.address_line_1',
                                      {
                                        validate: (value, allValues) =>
                                          validateCompanyField(
                                            'address_line_1',
                                            allValues
                                          ),
                                      }
                                    )}
                                  />
                                </div>
                              </Grid>
                              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <Input
                                    maxLength="5"
                                    type="text"
                                    id="address_number_company"
                                    label={t('common-address-number')}
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    error={
                                      errors?.company_address?.address_number
                                    }
                                    {...register(
                                      'company_address.address_number',
                                      {
                                        validate: (value, allValues) =>
                                          validateCompanyField(
                                            'address_number',
                                            allValues
                                          ),
                                      }
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>

                            <div className="form-control">
                              <label htmlFor="address_line_2_company">
                                {t('common-address-line-2')}
                              </label>
                              <input
                                maxLength="150"
                                type="text"
                                id="address_line_2_company"
                                disabled={
                                  profileStatus === HAC_STATUS.Exited ||
                                  profileStatus === HAC_STATUS.Nominated
                                }
                                {...register('company_address.address_line_2')}
                              />
                            </div>

                            <Grid container spacing={3}>
                              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <label htmlFor="country_company">
                                    {t('common-country')}
                                  </label>
                                  <select
                                    name="area_code_company"
                                    id="country_company"
                                    className="normal"
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    error={errors?.company_address?.area_code}
                                    {...register('company_address.area_code', {
                                      validate: (value, allValues) =>
                                        validateCompanyField(
                                          'area_code',
                                          allValues
                                        ),
                                    })}
                                  >
                                    <option key="" value="">
                                      {t('common-select-country')}
                                    </option>
                                    {countries.map((country) => (
                                      <option
                                        key={country.area_code}
                                        value={country.area_code}
                                      >
                                        {t('countries-' + country.area_code)}
                                      </option>
                                    ))}
                                  </select>
                                  {errors?.company_address?.area_code && (
                                    <span className="input__error">
                                      {errors.company_address.area_code.message}
                                    </span>
                                  )}
                                </div>
                              </Grid>
                              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <Input
                                    maxLength="50"
                                    type="text"
                                    id="city_company"
                                    label={t('common-city')}
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    error={errors?.company_address?.city}
                                    {...register('company_address.city', {
                                      validate: (value, allValues) =>
                                        validateCompanyField('city', allValues),
                                    })}
                                  />
                                </div>
                              </Grid>
                              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <Input
                                    maxLength="50"
                                    type="text"
                                    id="postal_code_company"
                                    label={t('common-zip-cp')}
                                    disabled={
                                      profileStatus === HAC_STATUS.Exited ||
                                      profileStatus === HAC_STATUS.Nominated
                                    }
                                    error={errors?.company_address?.postal_code}
                                    {...register(
                                      'company_address.postal_code',
                                      {
                                        validate: (value, allValues) =>
                                          validateCompanyField(
                                            'postal_code',
                                            allValues
                                          ),
                                      }
                                    )}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Collapse>
                    </>
                  )}
                  {profileStatus !== HAC_STATUS.Exited &&
                    profileStatus !== HAC_STATUS.Nominated && (
                      <Grid container spacing={3}>
                        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                          <button className="btn" type="submit">
                            {t('common-save')}
                          </button>
                        </Grid>
                      </Grid>
                    )}
                </form>
              </div>
            </section>
          </main>

          <ModalResendNomination
            open={resendModalOpen}
            handleClose={handleCloseResendModal}
            hacData={hacData}
          />
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default EditHacProfile;
