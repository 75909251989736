import './welcome.scss';
import Header from '../../organisms/header/header.js';
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import apiClient from '../../../utils/apiClient';
import { Snackbar, Alert } from '@mui/material';

function Welcome() {
  const { token } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isFirstCheckboxChecked, setIsFirstCheckboxChecked] = useState(false);
  /*const [isSecondCheckboxChecked, setIsSecondCheckboxChecked] = useState(false);*/

  const [language, setLanguage] = useState('');
  const [country, setCountry] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleFirstCheckboxChange = (event) => {
    setIsFirstCheckboxChecked(event.target.checked);
  };

/*  const handleSecondCheckboxChange = (event) => {
    setIsSecondCheckboxChecked(event.target.checked);
  };*/

  useEffect(() => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/hac/basic-info`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    apiClient
      .get(url, config)
      .then((response) => {
        setLanguage(response.data.preferredLanguage);
        setCountry(response.data.area_code);
        i18n.changeLanguage(response.data.preferredLanguage.toLowerCase());
        if (response.data.status !== 'nominated') {
          navigate(`/login`, {
            state: {
              lang: response.data.preferredLanguage.toLowerCase(),
            },
          })
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/expired';
        }
        else if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      });
    // eslint-disable-next-line
  }, [token, i18n]);

  const activateAccount = () => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/hac/activate`;

/*    const payload = {
      consents: isSecondCheckboxChecked,
    };*/

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    apiClient
      .patch(url, {}, config)
      .then((response) => {
        const absoluteUrl = response.data.link;
        const languageParam = i18n.language;
        const redirectUrl = new URL(absoluteUrl);
        redirectUrl.searchParams.set('preferredLanguage', languageParam);
        window.location.href = redirectUrl.toString();
      })
      .catch((error) => {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      });
  };

  // Construye dinámicamente los enlaces para el idioma actual
  const termsLink = `/legal/terms-and-conditions-${country}-${language}.pdf`;
  const privacyLink = `/legal/privacy-policy-${country}-${language}.pdf`;

  // Reemplaza los marcadores en el texto traducido
  const welcomeCheck1Text = t('welcome-check1')
    .replace('{termsLink}', termsLink)
    .replace('{privacyLink}', privacyLink);

  return (
    <>
      <div className="App">
        <Header />
        <main>
          <section className="welcome">
            <div className="inner">
              <h2>{t('welcome-title')}</h2>
              <p>{t('welcome-text1')}</p>
              <ul>
                <li>
                  <span className="icon-user"></span>
                  <p>{t('welcome-item1')}</p>
                </li>
                <li>
                  <span className="icon-car-shield"></span>
                  <p>{t('welcome-item2')}</p>
                </li>
                <li>
                  <span className="icon-tools"></span>
                  <p>{t('welcome-item3')}</p>
                </li>
                <li>
                  <span className="icon-edit"></span>
                  <p>{t('welcome-item4')}</p>
                </li>
                <li>
                  <span className="icon-pin"></span> <p>{t('welcome-item5')}</p>
                </li>
              </ul>
              <p>{t('welcome-text2')}</p>
              <div className="form-control">
                <FormControlLabel
                  control={<Checkbox onChange={handleFirstCheckboxChange} />}
                  label={<span dangerouslySetInnerHTML={{ __html: welcomeCheck1Text }} />}
                />
              </div>
{/*              <div className="form-control">
                <FormControlLabel
                  control={<Checkbox onChange={handleSecondCheckboxChange} />}
                  label={t('welcome-check2')}
                />
              </div>*/}
              <div className="actions">
                <button
                  className="btn"
                  disabled={!isFirstCheckboxChecked}
                  onClick={activateAccount}
                >
                  {t('welcome-action')}
                </button>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Welcome;
