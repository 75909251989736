import './org-hac-preferences.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { Collapse } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ModalPmaEditPreferences from './modal-hac-edit-preferences/modal-hac-edit-preferences.js';
import ModalWindow from '../modal/modal.js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { getPreferences } from '../../../../api/hacApi.js';
import { ROLES } from '../../../../constants/constants.js';
import decodeToken from '../../../../utils/decode-token.js';
import { deletePreference } from '../../../../api/hacApi.js';


export default function PreferencesHac() {
  const { t } = useTranslation();
  const { hacId } = useParams();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const [expandedItem, setExpandedItem] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState({});
  const [preferenceIdToDelete, setPreferenceIdToDelete] = useState(null);
  const [preferences, setPreferences] = useState([]);
  const [anchorEl, setAnchorEl] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await getPreferences(token, hacId);
      setPreferences(sortDataByCategoryAndTitle(response.data));
    } catch (error) {
      if (error.response) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    }
  }, [hacId, token, t]);

  function sortDataByCategoryAndTitle(data) {
    if (data.length > 0) {
      return data.sort((a, b) => {
        const categoryA = a.category_id.toLowerCase();
        const categoryB = b.category_id.toLowerCase();
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        if (categoryA < categoryB) return -1;
        if (categoryA > categoryB) return 1;
        if (titleA < titleB) return -1;
        if (titleA > titleB) return 1;
        return 0;
      });
    }
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExpand = (itemName) => {
    setExpandedItem((prevItem) => (prevItem === itemName ? null : itemName));
  };

  const handleClick = (event, index, item) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
    setSelectedPreference(item);
  };

  const handleClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setSelectedPreference({ title: '', value: '' });
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setPreferenceIdToDelete(null);
  };

  const open = (index) => Boolean(anchorEl[index]);

  const handleDeleteClick = (preferenceId) => {
    handleClose();
    setPreferenceIdToDelete(preferenceId);

 
    setDeleteModalOpen(true);
  };

   const handleDeletePreference = async () => {

     try {
       const token = sessionStorage.getItem('token') || null;
       await deletePreference(token, preferenceIdToDelete, hacId);
     
     } catch (error) {
       console.error('Error al eliminar la preferencia:', error);
     }
   };
  
  return (
    <>
      {role === ROLES.Retailer || role === ROLES.RetailerGM ? null : (
        <div className="actions">
          <button className="btn text" onClick={() => setEditModalOpen(true)}>
            <span className="icon-plus-circle"></span>{' '}
            {t('pma-preferences-add')}
          </button>
        </div>
      )}
      {Array.isArray(preferences) ? (
        <ul className="hac-preferences">
          {preferences.map((item, index) => (
            <li key={index}>
              <span>
                <span
                  className="header"
                  onClick={() => handleExpand(`preferences${index}`)}
                >
                  {expandedItem === `preferences${index}` ? (
                    <span className="icon-chevron-up"></span>
                  ) : (
                    <span className="icon-chevron-down"></span>
                  )}
                  <span>{t(`hac-preferences-${item.category_id}`)}</span>
                  <span>{item.title}</span>
                </span>
                <span
                  aria-controls={open(index) ? `menu${index}` : undefined}
                  aria-haspopup="true"
                  aria-expanded={open(index) ? 'true' : undefined}
                  onClick={(event) => handleClick(event, index, item)}
                >
                  <span className="icon-more"></span>
                </span>
              </span>
              <Collapse in={expandedItem === `preferences${index}`}>
                <p>{item.value}</p>
              </Collapse>
              {role === ROLES.Retailer || role === ROLES.RetailerGM ? null : (
                <Menu
                  className="submenu"
                  id={`menu${index}`}
                  anchorEl={anchorEl[index]}
                  open={open(index)}
                  onClose={() => handleClose(index)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose(index);
                      setEditModalOpen(true);
                    }}
                  >
                    <span className="icon-edit"></span>{' '}
                    {t('hac-preferences-menu1')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDeleteClick(item.id);
                      handleClose(index);
                    }}
                  >
                    <span className="icon-delete"></span>{' '}
                    {t('hac-preferences-menu2')}
                  </MenuItem>
                </Menu>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <h4>{t('hac-preferences-no-data')}</h4>
      )}
      <ModalPmaEditPreferences
        className="confirm"
        open={editModalOpen}
        handleClose={handleEditModalClose}
        selectedPreference={selectedPreference}
        hacId={hacId}
        onSave={fetchData}
      />

      <ModalWindow
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        entityId={preferenceIdToDelete}
        userId={hacId}
        postExecuteFunction={fetchData}
        executeFunction={handleDeletePreference}
        title={t('hac-preferences-delete-title')}
        text={t('hac-preferences-delete-text')}
        feedback={t('hac-preferences-delete-feedback')}
        textButtonCancel={t('common-cancel')}
        textoButtonConfirm={t('common-remove')}
        extraInfo={[]}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
