import axios from 'axios';
import decodeToken from './decode-token';

export const API_METHOD = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete'
};

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
  decompress: false
});

const refreshToken = async (refreshTokenValue) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshTokenValue}`,
        },
      }
    );
    sessionStorage.setItem('token', response.data.token);
    sessionStorage.setItem('refresh_token', response.data.refresh_token);
    return response.data.token;
  } catch (error) {
    console.error('Session expired. Please log in again.');
    throw error;
  }
};

apiClient.interceptors.request.use(
  async (config) => {
    let token = sessionStorage.getItem('token');
    const refreshTokenValue = sessionStorage.getItem('refresh_token');

    if (token) {
      const decodedToken = decodeToken(token);
      if (decodedToken) {
        const expirationTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        const timeUntilExpiration = expirationTime - currentTime;

        if (timeUntilExpiration < 60 * 1000 && refreshTokenValue) {
          token = await refreshToken(refreshTokenValue);
        }
      }
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
   (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('refresh_token');
    }
    return Promise.reject(error);
  }
);

const getHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const apiRequest = async (method, endpoint, token, data = null) => {
  const url = `${process.env.REACT_APP_API_URL}${endpoint}`;
  const config = getHeaders(token);
  try {
    if (['get', 'delete'].includes(method)) {
      return await apiClient[method](url, config);
    }
    return data !== null
      ? await apiClient[method](url, data, config)
      : await apiClient[method](url, config);     
  } catch (error) {
    console.error(`Error in ${method.toUpperCase()} ${url}:`, error);
    throw error;
  }
};

export  default apiClient;
