import React from "react";
import "./textarea.scss";
import "../../assets/scss/_variables.scss";
import clsx from "clsx";

function Textarea({ id, label, error, className, ...props }, ref) {
  return (
    <>
      <label
        htmlFor={id}
        className={clsx("textarea_label", { textarea_label__error: error })}
      >
        {label}
      </label>
      <textarea
        maxLength="255"
        id={id}
        ref={ref}
        {...props}
        className={'txt-area' + clsx(className, { error: error })}
      />
      {error && <p className="textarea__error">{error.message}</p>}
    </>
  );
}
Textarea.displayName = "Textarea";

export default React.forwardRef(Textarea);
