import { API_METHOD, apiRequest } from '../utils/apiClient';

export const inactivePma = (token, pmaId) =>
  apiRequest(API_METHOD.POST, `/pma/inactive/${pmaId}`, token);

export const PmaUnenrrollHac = (token, hacId, payload) =>
  apiRequest(
    API_METHOD.PUT,
    `/pma2/hac-exit?hacId=${hacId}`,
    token,
    payload
  );
