import './activity-log.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useLocation, useParams} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import dayjs from 'dayjs';
import { fetchNotifications } from '../../../../api/hacApi';
import { ROLES } from '../../../../constants/constants';

function ActivityLog() { 
  const {t} = useTranslation();
  const {hacId} = useParams();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  const [notifications, setNotifications] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (hacId) {
      const fetchData = async () => {
        try {
          const response = await fetchNotifications(token, hacId);
          if (response && (response.status === 200 || response.status === 204)) {
            setNotifications(response.data || null); 
            setDataLoaded(true);
          }
        } catch (error) {
          console.error('Error al obtener notificaciones:', error);
        }
      };

      fetchData();
    }
  }, [hacId, token, t]);
  return (
    <>
      {token &&
      (role === ROLES.Pma ||
        role === ROLES.PmaGM ||
        role === ROLES.Admin ||
        role === ROLES.CountryAdmin ||
        role === ROLES.MarketAdmin) ? (
        <div className="App">
          <Header />
          <main>
            <section className="activity-log">
              <div className="inner">
                <Link
                  to={`/${
                    role === ROLES.Admin ||
                    role === ROLES.CountryAdmin ||
                    role === ROLES.MarketAdmin
                      ? `${ROLES.Admin}/main`
                      : role === ROLES.Pma || role === ROLES.PmaGM
                      ? `${ROLES.Pma}/main`
                      : '/login'
                  }`}
                  className="back-button"
                >
                  <span className="icon-chevron-left"></span> {t('common-back')}{' '}
                  / {breadcrumbName}
                </Link>

                <h2>{t('activity-log-title')}</h2>
                <p>{t('activity-log-text')}</p>

                {dataLoaded ? (
                  notifications ? (
                    <div>
                      {notifications.map((notification) => {
                        const formattedDate = dayjs(
                          notification.timestamp
                        ).format('DD/MM/YYYY');
                        const formattedTime = dayjs(
                          notification.timestamp
                        ).format('HH:mm');

                        return (
                          <div
                            key={notification.id}
                            className="notification-item"
                          >
                            <header>
                              <span className="text">
                                {t(
                                  `activity-log-${notification.section}-${notification.operation}`
                                )}
                              </span>
                              <span className="date">
                                {formattedDate} - {formattedTime}
                              </span>
                            </header>
                            <p>
                              {notification.actor_name} -{' '}
                              {t(`common-role-${notification.actor_role}`)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <strong>{t('activity-log-empty')}</strong>
                  )
                ) : (
                  <p>{t('common-loading')}</p>
                )}
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default ActivityLog;
