import './org-vehicles.scss';
import React, { useEffect, useState, useCallback } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Collapse, Snackbar} from '@mui/material';
import ModalWindow from '../modal/modal.js';
import {Link, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';
import { Alert } from '@mui/material';
import { fetchVehicles, deleteVehicle } from '../../../../api/hacApi.js';


function EditHacVehicle({ breadcrumbName }) {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;

  const { hacId } = useParams();

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicleId, setSelectedVehicleId] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedPlate, setSelectedPlate] = useState(null);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState({});
  const [expanded, setExpanded] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await fetchVehicles(token, hacId);
      setVehicles(response.data);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(t('common-internal-server-error'), error);
    }
  }, [token, hacId, t]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleClick = (id) => (event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleMoreInfoClick = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleDeleteClick = (id, model, plate) => {
    handleClose();
    setSelectedVehicleId(id);

    setSelectedModel(model);
    setSelectedPlate(plate);
    setDeleteModalOpen(true);
  };

  const handleDeleteVehicle = async (token, hacId, vehicleId) => {
    try {     
      await deleteVehicle(token, hacId, vehicleId);
    } catch (error) {
      console.error('Error al borrar el vehículo:', error);
    }
  };

  return (
    <>
      <div className="vehicles">
        <div className="actions">
          <Link
            className="btn text"
            to="/common/vehicles/new-vehicle"
            state={{ hacId: hacId, breadcrumbName: breadcrumbName }}
          >
            [+] {t('hac-my-profile-vehicles-add')}
          </Link>
        </div>

        {vehicles.length > 0 ? (
          vehicles.map((item, index) => (
            <div className="item" key={index}>
              <header>
                <h2>
                  {item.brand} {item.model}
                  <small>
                    {t('common-license-plate')}:{' '}
                    <strong>{item.license_plate}</strong>
                  </small>
                </h2>
                <span
                  className="btn text"
                  aria-controls={anchorEl[item.id] ? `menu${index}` : undefined}
                  aria-haspopup="true"
                  aria-expanded={
                    Boolean(anchorEl[item.id]) ? 'true' : undefined
                  }
                  onClick={handleClick(item.id)}
                >
                  <span className="icon-more"></span>
                </span>
                <Menu
                  className="submenu"
                  id={`${item.id}`}
                  anchorEl={anchorEl[item.id]}
                  open={Boolean(anchorEl[item.id])}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem>
                    <Link
                      to={`/common/vehicles/edit-vehicle`}
                      state={{
                        vehicle: item,
                        hacId: hacId,
                        breadcrumbName: breadcrumbName,
                      }}
                    >
                      <span className="icon-edit"></span>{' '}
                      {t('hac-my-profile-vehicles-menu1')}
                    </Link>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleDeleteClick(item.id, item.model, item.license_plate)
                    }
                  >
                    <span className="icon-delete"></span>
                    {t('hac-my-profile-vehicles-menu2')}
                  </MenuItem>
                </Menu>
              </header>
              <Collapse in={expanded === index}>
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                    <ul>
                      {/* <li>
                        <span>{t('common-vista-number')}</span>
                        <strong>{item.vista_number}</strong>
                      </li> */}
                      {/* <li>
                        <span>{t('common-date-signature')}</span>
                        <strong>{item.signature_date}</strong>
                      </li> */}
                      <li>
                        <span>{t('common-vin')}</span>
                        <strong>{item.vin}</strong>
                      </li>
                      {/* <li>
                        <span>{t('common-order-date')}</span>
                        <strong>{item.order_date}</strong>
                      </li> */}
                      {/* <li>
                        <span>{t('common-registration-date')}</span>
                        <strong>{item.registration_date}</strong>
                      </li> */}
                      <li>
                        <span>{t('common-purchase-date')}</span>
                        <strong>{item.purchase_date}</strong>
                      </li>
                    </ul>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                    <ul>
                      <li>
                        <span>{t('common-retailer-country')}</span>
                        {item.retailer_area_code ? (
                          <strong>
                            {t(`countries-${item.retailer_area_code}`)}
                          </strong>
                        ) : null}
                      </li>
                      <li>
                        <span>{t('common-retailer-city')}</span>
                        <strong>{item.retailer_city}</strong>
                      </li>
                      <li>
                        <span>{t('common-retailer-name')}</span>
                        <strong>{item.retailer_name}</strong>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Collapse>
              <div className="more-info">
                <button
                  className="btn text"
                  onClick={() => handleMoreInfoClick(index)}
                >
                  {t('hac-my-profile-vehicles-expand')}{' '}
                  {expanded === index ? (
                    <span className="icon-chevron-up"></span>
                  ) : (
                    <span className="icon-chevron-down"></span>
                  )}
                </button>
              </div>
            </div>
          ))
        ) : (
          <h4>{t('hac-preferences-no-data')}</h4>
        )}
      </div>
      <ModalWindow
        open={deleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        entityId={selectedVehicleId}
        userId={hacId}
        postExecuteFunction={fetchData}
        executeFunction={handleDeleteVehicle}
        title={t('hac-vehicle-delete-title')}
        text={t('hac-vehicle-delete-text')}
        feedback={t('hac-vehicle-delete-feedback')}
        extraInfo={[selectedModel, selectedPlate]}
        textButtonCancel={t('common-cancel')}
        textoButtonConfirm={t('common-remove')}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {t('feedback-save-vehicle-ok')}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default EditHacVehicle;
