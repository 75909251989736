import './edit-admin.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Alert, InputBase, Snackbar} from '@mui/material';
import Grid from "@mui/material/Grid2";
import Input from "../../../atoms/input";
import apiClient from "../../../../utils/apiClient";
import {useForm} from 'react-hook-form';
import {jwtDecode} from 'jwt-decode';
import Joi from "joi";
import {joiResolver} from "@hookform/resolvers/joi";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import FormControlLabel from "@mui/material/FormControlLabel";

function EditAdmin() {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const decodedToken = jwtDecode(token);
  const role = decodedToken.role;

  const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [userRole, setUserRole] = useState('');
  const [selectedMarkets, setSelectedMarkets] = useState([]);
  const [selectedMarketAreaCode, setSelectedMarketAreaCode] = useState([]);
  const [phoneError, setPhoneError] = useState('');
  const location = useLocation();
  const adminId = location.state?.id || null;
  const { breadcrumbName } = location.state || {};


  const [canNominate, setCanNominate] = useState(false);
  const handleNominateChange = (event) => {
    setCanNominate(event.target.checked); // Actualiza el estado con el valor del checkbox
  };

  const markets = [
    {name: 'AU_CZ_SK', countries: ['AT', 'CZ', 'SK']},
    {name: 'Benelux', countries: ['BE', 'LUX', 'NL']},
    { name: 'EIRO', countries: ['PL'] },
    {name: 'France', countries: ['FR']},
    {name: 'Germany', countries: ['DE']},
    {name: 'Iberia', countries: ['ES', 'PT']},
    {name: 'Italy', countries: ['IT']},
    {name: 'Switzerland', countries: ['CH']},
    {name: 'Turkey', countries: ['TR']},
  ];

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Esquema de validación con Joi
  const schema = Joi.object({
    name: Joi.string().required().messages({'string.empty': t('alert-name')}),
    last_name: Joi.string().required().messages({'string.empty': t('alert-last-name')}),
    email: Joi.string().trim().email({tlds: {allow: false}}).required().messages({
      'string.email': t('alert-email-format'),
      'string.empty': t('alert-email'),
    }),
    prefix_phone: Joi.string().pattern(/^\+[0-9]{2,3}$/).required().messages({
      'string.pattern.base': t('alert-prefix'),
      'string.empty': t('alert-prefix'),
    }),
    phone: Joi.string().pattern(/^[0-9]{7,15}$/).required().messages({
      'string.pattern.base': t('alert-phone-format'),
      'string.empty': t('alert-phone'),
    }),
    area_code: Joi.string().required().messages({'string.empty': t('alert-country')}),
    city: Joi.string().required().messages({'string.empty': t('alert-city')}),
    preferred_language: Joi.string().required().messages({'string.empty': t('alert-language')}),
    role: Joi.string().required().messages({'string.empty': t('alert-role')}),
    market: Joi.array()
      .when(Joi.ref('role'), {
        is: 'marketAdmin', // Solo es requerido si el rol es marketAdmin
        then: Joi.array().min(1).required().messages({
          'array.base': t('alert-market'), // Mensaje si no es un array
          'array.min': t('alert-market')    // Mensaje si el array está vacío
        }),
        otherwise: Joi.array().optional() // Opcional si no es marketAdmin
      }),
    managed_area_codes: Joi.array().min(1).required().messages({
      'array.base': t('alert-country'),
      'array.min': t('alert-country'),
      'any.required': t('alert-country')
    }),
  });


  // Uso de react-hook-form y Joi para manejar y validar el formulario
  const {register, handleSubmit, formState, setValue, setFocus} = useForm({
    resolver: joiResolver(schema),
    mode: 'onChange'
  });
  const {errors} = formState;

  // Llamada para obtener los países y establecer el estado de countries
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/hac/countries`);
        const filteredCountries = response.data.filter(country => country.area_code !== 'EN');
        setCountries(filteredCountries);
      } catch (error) {
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'));
      }
    };
    fetchCountries();
  }, [t]);

  // Manejo del envío de formulario con validación
  const onSubmit = async (data) => {

    const fullPhoneNumber = parsePhoneNumberFromString(
      data.prefix_phone + data.phone
    );


    if (!fullPhoneNumber.isValid()) {
      setPhoneError(t('alert-phone-format'));
      setFocus('phone');
      return;
    } else {
      setPhoneError('');
    }

    const payload =
      {
        name: data.name,
        last_name: data.last_name,
        email: data.email,
        prefix_phone: data.prefix_phone,
        phone: data.phone,
        area_code: data.area_code,
        city: data.city,
        preferred_language: data.preferred_language,
        managed_area_codes: selectedMarketAreaCode,
        nominate: canNominate
      };

    try {
      const response = await apiClient.put(
        `${process.env.REACT_APP_API_URL}/admin/${adminId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSnackbarMessage(t('alert-data-saved'));
        setSnackbarOpen(true);
        setSnackbarSeverity('success');
        const adminData = JSON.parse(sessionStorage.getItem('loggedUser'));


        if (adminData) {
          adminData.nominate = canNominate;  // Cambiar el valor de 'nominate' a 1 (o el valor que desees)

          // Guardar el objeto actualizado de nuevo en sessionStorage
          sessionStorage.setItem('loggedUser', JSON.stringify(adminData));
        }
        setTimeout(() => navigate("/admin/main/admin"), 2000);
      }
    } catch (error) {
      if (error.response?.status === 409) {
        setSnackbarMessage(t('alert-duplicate'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarOpen(true);
      }
    }
  };

  const onError = (errors) => {
    //console.log('Validation Errors:', errors);
    //console.log('Market Area Code:', selectedMarketAreaCode);
  };

  // Manejo del cambio de codigo de area (pais del usuario, no países que va a administrar)
  const handleAreaCodeChange = (event) => {
    const {value} = event.target;
    setValue('area_code', value); // Actualiza el valor en react-hook-form

    // Filtrar idiomas según el país seleccionado
    const selectedCountry = countries.find(country => country.area_code === value);
    if (selectedCountry) {
      setLanguages(selectedCountry.language || []); // Asegúrate de que se asignan los idiomas
    } else {
      setLanguages([]); // Restablecer idiomas si no hay país seleccionado
    }
  };


  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/admin/${adminId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const adminData = response.data;

          // Asignar valores a los inputs
          setCanNominate(adminData.nominate === 1)
          setValue('name', adminData.name || '');
          setValue('last_name', adminData.last_name || '');
          setValue('email', adminData.email || '');
          setValue('prefix_phone', adminData.prefix_phone || '');
          setValue('phone', adminData.phone || '');
          setValue('city', adminData.city || '');
          setValue('area_code', adminData.area_code || '');
          setValue('managed_area_codes', adminData.managed_area_codes || []);
          setValue('role', adminData.role || '');
          setUserRole(adminData.role);


          // Filtrar idiomas según el país recibido
          const country = countries.find((c) => c.area_code === adminData.area_code);
          if (country && country.language) {
            setLanguages(country.language);
            setTimeout(() => {
              setValue('preferred_language', adminData.preferred_language || '');
            }, 0);
          } else {
            setLanguages([]);
            setValue('preferred_language', '');
          }

          // Evitar llenar 'markets' si el rol es 'countryAdmin'
          if (adminData.role !== 'countryAdmin') {
            setValue('market', adminData.market || []);
            setSelectedMarkets(adminData.market || []);
          } else {
            setValue('market', []); // Limpia el campo si es 'countryAdmin'
            setSelectedMarkets([]); // Limpia el estado local
          }


          // Actualizar estados locales
          setSelectedMarketAreaCode(adminData.managed_area_codes || []);
        }
      } catch (error) {
        console.error('Error fetching admin data:', error);
        setSnackbarMessage(t('common-internal-server-error'));
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    };

    if (adminId) {
      fetchAdminData();
    }
  }, [adminId, token, setValue, t, countries]);

  const handleMarketChange = (event) => {
    const {value} = event.target;
    setSelectedMarkets(value); // Esto actualizará selectedMarkets

    const selectedCountries = [];
    value.forEach(marketName => {
      const market = markets.find(m => m.name === marketName);
      if (market) {
        selectedCountries.push(...market.countries);
      }
    });
    setSelectedMarketAreaCode(selectedCountries); // Esto actualizará selectedMarketAreaCode
    setValue('managed_area_codes', Array.isArray(selectedCountries) ? value : [value]);
    setValue('market', Array.isArray(selectedMarkets) ? value : [value]);
  };

  const handleManagedAreaCodeChange = (event) => {
    const {value} = event.target;

    const uniqueValues = Array.isArray(value) ? [...new Set(value)] : [value];

    setSelectedMarketAreaCode(uniqueValues);

    setValue('managed_area_codes', uniqueValues);
  };


  return (
    <>
      {token && (role === 'admin' || role === 'marketAdmin' || role === 'countryAdmin') ? (
        <div className="App">
          <Header/>
          <main>
            <section className="form">
              <div className="inner">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                  <Link to="/admin/main/admin" className="back-button">
                    <span className="icon-chevron-left"></span> {t('common-back')} / {breadcrumbName}
                  </Link>
                  <h2>
                    {t('admin-edit-admin-title')}
                  </h2>

                  <div>
                    <p><strong>{t('common-basic-info')}</strong></p>

                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                        <div className="form-control required">
                          <Input
                            autoComplete="off"
                            maxLength="50"
                            type="text"
                            id="name"
                            label={t('common-name')}
                            error={errors?.name}
                            {...register('name')}
                          />
                        </div>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="last_name"
                            label={t('common-last-name')}
                            error={errors?.last_name}
                            {...register('last_name')}
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            autoComplete="off"
                            id="email"
                            label={t('common-email')}
                            error={errors?.email}
                            readOnly
                            {...register('email')}
                          />
                        </div>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                        <div>
                          <Grid container spacing={1}>
                            <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  id="prefix_phone"
                                  placeholder="+xx/+xxx"
                                  autoComplete="off"
                                  label={t('common-prefix')}
                                  error={errors?.prefix_phone}
                                  {...register('prefix_phone')}
                                />
                              </div>
                            </Grid>
                            <Grid size={{ xs: 8, sm: 8, md: 8 }}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  autoComplete="off"
                                  id="phone"
                                  label={t('common-phone')}
                                  error={errors?.phone}
                                  {...register('phone')}
                                />
                                {phoneError && (
                                  <p className="error-message">{phoneError}</p>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                        <div className="form-control required">
                          <label htmlFor="area_code">{t('common-select-country')}</label>
                          <select
                            name="area_code"
                            id="area_code"
                            className="normal"
                            {...register('area_code')}
                            onChange={handleAreaCodeChange}
                          >
                            <option value="">{t('common-select-country')}</option>
                            {countries.map(country => (
                              <option key={country.area_code} value={country.area_code}>
                                {country.country}
                              </option>
                            ))}
                          </select>
                          {errors.area_code && (
                            <span className="input__error">{errors.area_code.message}</span>
                          )}
                        </div>
                      </Grid>
                      <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="city"
                            label={t('common-city')}
                            error={errors?.city}
                            {...register('city')}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <div className="form-control required">
                          <label htmlFor="preferred_language">{t('common-select-language')}</label>
                          <select
                            name="preferred_language"
                            id="preferred_language"
                            className="normal"
                            {...register('preferred_language')}
                          >
                            <option value="">{t('common-select-language')}</option>
                            {languages.map((language, index) => (
                              <option key={index} value={language}>
                                {t(`languages-${language}`)}
                              </option>
                            ))}
                          </select>
                          {errors.preferred_language && (
                            <span className="input__error">{errors.preferred_language.message}</span>
                          )}
                        </div>
                      </Grid>
                    </Grid>



                      <>
                        <br/><br/>
                        <p><strong>{t('common-settings-admin')}</strong></p>
                        <Grid container spacing={3}>
                          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                            <div className="form-control required">
                              <label htmlFor="role">{t('common-admin-role')}</label>
                              <select
                                name="role"
                                id="role"
                                className="normal"
                                disabled
                                {...register('role')}
                              >


                                <option value="admin">{t('common-role-admin')}</option>
                                <option value="marketAdmin">{t('common-role-marketAdmin')}</option>
                                <option value="countryAdmin">{t('common-role-countryAdmin')}</option>
                                <option value="shippingAdmin">{t('common-role-shipping-admin')}</option>
                              </select>
                              {errors.role && (
                                <span className="input__error">{errors.role.message}</span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                            <div className={`form-control ${userRole === 'marketAdmin' ? 'required' : ''}`}>
                              <label htmlFor="market">{t('common-manage-market')}</label>

                              <FormControl fullWidth variant="filled">
                                <Select
                                  className="multiselect"
                                  id="market"
                                  multiple
                                  disabled={userRole !== 'marketAdmin'} // Deshabilitar si no es marketAdmin
                                  value={selectedMarkets}
                                  onChange={handleMarketChange} // Manejar cambio de mercados
                                  input={<InputBase name="market" id="market" label={t('common-manage-market')}/>}
                                  renderValue={(selected) => selected.join(', ')}
                                  variant="filled"
                                >
                                  {markets
                                    .filter(market => {
                                      if (loggedUser.role === 'admin') {
                                        return true; // Mostrar todos los mercados si es admin
                                      }
                                      if (loggedUser.role === 'marketAdmin') {
                                        return loggedUser.market.includes(market.name); // Mostrar solo mercados asignados a marketAdmin
                                      }
                                      return false; // No mostrar mercados para otros roles
                                    })
                                    .map(market => (
                                      <MenuItem key={market.name} value={market.name} className="multiselect-options">
                                        <Checkbox checked={selectedMarkets.includes(market.name)}/>
                                        <ListItemText primary={market.name}/>
                                      </MenuItem>
                                    ))}
                                </Select>
                                {errors.market && (
                                  <span className="input__error">{errors.market.message}</span>
                                )}
                              </FormControl>


                            </div>
                          </Grid>

                          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                            <div className="form-control required">
                              <label htmlFor="managed_area_codes">{t('common-manage-country')}</label>
                              <FormControl fullWidth>
                                <Select
                                  id="managed_area_codes"
                                  className="multiselect"
                                  multiple
                                  value={selectedMarketAreaCode}
                                  onChange={handleManagedAreaCodeChange}
                                  input={<InputBase name="managed_area_codes" id="managed_area_codes"/>}
                                  renderValue={(selected) => selected.join(', ')}
                                  disabled={userRole !== 'countryAdmin'}
                                >
                                  {loggedUser.role === 'admin'
                                    ? countries.map((country) => (
                                      <MenuItem key={country.area_code} value={country.area_code}
                                                className="multiselect-options">
                                        <Checkbox checked={selectedMarketAreaCode.includes(country.area_code)}/>
                                        <ListItemText primary={country.country}/>
                                      </MenuItem>
                                    ))
                                    : countries
                                      .filter((country) => loggedUser.managed_area_codes.includes(country.area_code))
                                      .map((country) => (
                                        <MenuItem key={country.area_code} value={country.area_code}>
                                          <Checkbox checked={selectedMarketAreaCode.includes(country.area_code)}/>
                                          <ListItemText primary={country.country}/>
                                        </MenuItem>
                                      ))}
                                </Select>
                              </FormControl>
                            </div>
                          </Grid>
                        </Grid>
                        {loggedUser.role === 'admin' && userRole !== 'shippingAdmin' && (
                          <div className="form-control">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={canNominate}
                                  onChange={handleNominateChange}
                                />
                              }
                              label={t('admin-new-admin-nominate')}
                            />
                          </div>
                        )}
                      </>


                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <button className="btn" type="submit">
                          {t('common-save')}
                        </button>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </div>
            </section>
          </main>
          <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
            <Alert severity={snackbarSeverity} sx={{width: '100%'}}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login"/>
      )}
    </>
  );
}

export default EditAdmin;
