import './profile.scss';
import React, { useEffect, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import apiClient from '../../../../utils/apiClient';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Grid from '@mui/material/Grid2';
import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';
import Input from '../../../atoms/input.js';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import { Alert, Collapse, Snackbar } from '@mui/material';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const setValues = (values, setterFn) => {
  const requiredValues = [
    'name',
    'email',
    'middle_name',
    'last_name',
    'phone',
    'preferred_address',
    'prefix_phone',
    'title',
    'academic_title',
    'birth_day',
    'birth_day_partner',
    'name_partner',
    'last_name_partner',
  ];
  for (const [key, value] of Object.entries(values)) {
    if (requiredValues.includes(key) && value) {
      setterFn(key, value);
    }
  }
};

function HacProfile() {
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const { t } = useTranslation();

  const id = decodeToken(token).id;

  const [countries, setCountries] = useState([]);
  const [areaCode, setAreaCode] = useState('');
  const [academicTitles, setAcademicTitles] = useState([]);
  const [hacData] = useState({});
  const [profileStatus, setProfileStatus] = useState('nominated');
  const [phoneError, setPhoneError] = useState('');

  const {
    formState,
    register,
    handleSubmit,
    setValue,
    watch,
    resetField,
    setFocus,
    control,
  } = useForm();

  const [preferredAddress, setPreferredAddress] = useState('');

  const privateAddress = watch('private_address');
  const companyAddress = watch('company_address');

  const { errors } = formState;

  const [expandedSections, setExpandedSections] = useState({
    basic: true,
    address: false,
  });

  const toggleSection = (section) => {
    setExpandedSections({
      ...expandedSections,
      [section]: !expandedSections[section],
    });
  };

  useEffect(() => {
    const fetchAcademicTitles = async () => {
      if (areaCode) {
        try {
          const response = await apiClient.get(
            `${process.env.REACT_APP_API_URL}/hac/academic-titles?area_code=${areaCode}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setAcademicTitles(response.data);
        } catch (error) {
          console.error('Error al obtener los títulos académicos:', error);
          if (error.response) {
            setSnackbarOpen(true);
            setSnackbarMessage(t('common-internal-server-error'), error);
          }
        }
      }
    };

    fetchAcademicTitles();
  }, [areaCode, token, t]);

  useEffect(() => {
    if (hacData.academic_title) {
      setValue('academic_title', hacData.academic_title);
    }
  }, [hacData, setValue]);

  useEffect(() => {
    apiClient
      .get(`${process.env.REACT_APP_API_URL}/hac/countries`)
      .then(({ data }) => setCountries(data))
      .catch(console.error);
  }, []);

  useState(() => {
    const fetchPreferredAddress = async () => {
      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/hac?hacId=${id}`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const response = await apiClient.get(url, config);
        setPreferredAddress(response.data.preferred_address);
      } catch (error) {
        console.error('Error fetching preferred address:', error);
      }
    };

    fetchPreferredAddress();
  }, []);

  useEffect(() => {
    const authToken = token;
    const url = `${process.env.REACT_APP_API_URL}/hac?hacId=${id}`;

    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    apiClient
      .get(url, config)
      .then((response) => {
        if (response.status !== 200)
          throw new Error("It couldn't retry hac profile");
        setProfileStatus(response.data.status);
        setValues(response.data, setValue);
        setAreaCode(response.data.area_code);
        const privateAddr = response.data.address.find(
          (address) => address.address_type === 'private'
        );
        const companyAddr = response.data.address.find(
          (address) => address.address_type === 'company'
        );
        if (companyAddr) {
          setValue('company_address', companyAddr);
        }
        if (privateAddr) {
          setValue('private_address', privateAddr);
        }
      })
      .catch((error) => {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      });
    // eslint-disable-next-line
  }, []);

  const handlePreferredAddressChange = (event) => {
    setPreferredAddress(event.target.value);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleClearAddress = async (type) => {
    const address = type === 'private' ? privateAddress : companyAddress;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
      params: {
        hacId: address.hac,
      },
    };

    try {
      await apiClient.delete(
        `${process.env.REACT_APP_API_URL}/hac/address/${address.id}`,
        config
      );
      Object.keys(address).forEach((key) => {
        address[key] = undefined;
        resetField(`${type}_address.${key}`);
      });

      setSnackbarMessage(t('alert-address-deleted'));
      setSnackbarSeverity('success');
    } catch (error) {
      setSnackbarMessage(
        error.response?.status === 409
          ? t('alert-address-delete-preferred')
          : t('common-internal-server-error')
      );
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const onSubmit = handleSubmit(
    async ({
             private_address,
             company_address,
             middle_name,
             birth_day,
             birth_day_partner,
             name_partner,
             last_name_partner,
             ...rest
           }) => {
      if (Object.keys(errors).length) return;
      let payload = {
        ...rest,
        middle_name: middle_name.length ? middle_name : undefined,
        name_partner: name_partner.length ? name_partner : undefined,
        last_name_partner: last_name_partner.length
          ? last_name_partner
          : undefined,
        birth_day: birth_day ? birth_day : undefined,
        birth_day_partner: birth_day_partner ? birth_day_partner : undefined,
        preferred_address: preferredAddress ? preferredAddress : undefined,
        address: [],
      };

      if (private_address.alias) {
        payload.address.push({
          id: private_address.id,
          alias: private_address.alias,
          area_code: private_address.area_code,
          address_type: 'private',
          address_line_1: private_address.address_line_1,
          address_number: private_address.address_number,
          city: private_address.city,
          postal_code: private_address.postal_code,
          address_line_2: private_address.address_line_2
            ? private_address.address_line_2
            : undefined,
        });
      }

      if (company_address.company_name) {
        payload.address.push({
          id: company_address.id,
          company_name: company_address.company_name,
          area_code: company_address.area_code,
          address_type: 'company',
          address_line_1: company_address.address_line_1,
          address_number: company_address.address_number,
          city: company_address.city,
          postal_code: company_address.postal_code,
          address_line_2: company_address.address_line_2
            ? company_address.address_line_2
            : undefined,
        });
      }

      if (payload.address.length === 0) {
        delete payload.address;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
        },
      };
      try {
        const fullPhoneNumber = parsePhoneNumberFromString(
          payload.prefix_phone + payload.phone
        );

        if (!fullPhoneNumber.isValid()) {
          setPhoneError(t('alert-phone-format'));
          setFocus('phone');
          /*phoneInputRef.current.focus();*/
          return;
        } else {
          setPhoneError('');

          await apiClient.put(
            `${process.env.REACT_APP_API_URL}/hac/${id}`,
            JSON.parse(JSON.stringify(payload)),
            config
          );
          setSnackbarMessage(t('alert-data-saved'));
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 413) {
            setSnackbarOpen(true);
            setSnackbarMessage('Street Address too large');
          } else {
            setSnackbarOpen(true);
            setSnackbarMessage(t('common-internal-server-error'), error);
          }
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage(error);
        }
      }
    }
  );

  const validateAddressField = (field, allFields) => {
    const privateAddress = allFields.private_address;
    const isPrivateAddressSelected = preferredAddress === 'private';

    const isAnyFieldFilled = Object.values(privateAddress).some(
      (val) => val !== undefined && val !== ''
    );

    if (isAnyFieldFilled || isPrivateAddressSelected) {
      if (!privateAddress[field]) {
        return t('alert-address-must-complete');
      }
    }
    return true;
  };

  const validateCompanyField = (field, allFields) => {
    const companyAddress = allFields.company_address;
    const isCompanyAddressSelected = preferredAddress === 'company';

    const isAnyFieldFilled = Object.values(companyAddress).some(
      (val) => val !== undefined && val !== ''
    );

    if (isAnyFieldFilled || isCompanyAddressSelected) {
      if (!companyAddress[field]) {
        return t('alert-address-must-complete');
      }
    }
    return true;
  };

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header />
          <main>
            <Wadsworth />

            <section className="profile">
              <div className="inner menu">
                <aside className="lateral-menu">
                  <h2>{t('hac-menu-profile-title')}</h2>
                  <ul>
                    <li className="on">
                      <Link to="/hac/profile/basic-info">
                        {t('hac-menu-profile-menu1')}
                      </Link>
                    </li>
                    <li>
                      <Link to="/hac/profile/vehicles">
                        {t('hac-menu-profile-menu2')}
                      </Link>
                    </li>
                  </ul>
                </aside>
                <main className="profile">
                  <form onSubmit={onSubmit}>
                    <Link to="/hac/main" className="back-button">
                      <span className="icon-chevron-left"></span>{' '}
                      {t('common-back')}
                    </Link>
                    <h2>{t('hac-my-profile-info-title')}</h2>
                    <p>{t('hac-my-profile-info-text')}</p>

                    <div
                      className="title"
                      onClick={() => toggleSection('basic')}
                    >
                      <h5>{t('hac-menu-profile-menu1')}</h5>
                      <span className="btn text">
                        {expandedSections.basic ? (
                          <span className="icon-minus"></span>
                        ) : (
                          <span className="icon-plus"></span>
                        )}
                      </span>
                    </div>
                    <Collapse in={expandedSections.basic}>
                      <Grid container spacing={3}>
                        <Grid size={{ xs: 6, sm: 4, md: 2 }}>
                          <div className="form-control">
                            <label htmlFor="title">{t('common-title')}</label>
                            <select
                              name="title"
                              id="title"
                              className="normal"
                              {...register('title')}
                            >
                              <option value="Mr">{t('common-title-Mr')}</option>
                              <option value="Mrs">{t('common-title-Mrs')}</option>
                              <option value="Mx">{t('common-title-Mx')}</option>
                            </select>
                          </div>
                        </Grid>
                        {academicTitles.length !== 0 && (
                          <Grid size={{ xs: 6, sm: 4, md: 4 }}>
                            <div className="form-control">
                            <label htmlFor="academic-title">
                                {t('common-academic-title')}
                              </label>
                              <select
                                name="academic-title"
                                id="academic-title"
                                className="normal"
                                {...register('academic_title')}
                                disabled={profileStatus === 'nominated'}
                              >
                                <option key="" value="">
                                  {t('common-select-academic-title')}
                                </option>
                                {Array.isArray(academicTitles) &&
                                  academicTitles.map((academicTitle) => (
                                    <option
                                      key={academicTitle.sv_value}
                                      value={academicTitle.sv_value}
                                    >
                                      {academicTitle.text}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </Grid>
                        )}
                        <Grid size={{ xs: 12, sm: 8, md: 5 }}>
                          <div className="form-control required">
                            <Input
                              maxLength="50"
                              type="text"
                              id="name"
                              label={t('common-first-name')}
                              error={errors?.name}
                              {...register('name', {
                                validate: (v) => {
                                  const { error } = Joi.string()
                                    .required()
                                    .error(() => new Error(t('alert-name')))
                                    .validate(v);
                                  return error?.message ? error.message : true;
                                },
                              })}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid size={{ xs: 12, sm: 4, md: 5 }}>
                          <div className="form-control">
                            <label htmlFor="middle_mame">
                              {t('common-middle-name')}
                            </label>
                            <input
                              maxLength="50"
                              type="text"
                              id="middle_mame"
                              {...register('middle_name', {})}
                            />
                          </div>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 5, md: 5 }}>
                          <div className="form-control required">
                            <Input
                              maxLength="50"
                              type="text"
                              id="last_name"
                              label={t('common-last-name')}
                              error={errors?.last_name}
                              {...register('last_name', {
                                validate: (v) => {
                                  const { error } = Joi.string()
                                    .required()
                                    .error(
                                      () => new Error(t('alert-last-name'))
                                    )
                                    .validate(v);
                                  return error?.message ? error.message : true;
                                },
                              })}
                            />
                          </div>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 3, md: 2 }}>
                          <div className="form-control datepicker">
                            <label htmlFor="birth_day">
                              {t('common-birthday')}
                            </label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Controller
                                name="birth_day"
                                control={control}
                                render={({ field }) => (
                                  <DateField
                                    {...field}
                                    format="DD/MM"
                                    onChange={(date) => {
                                      const isValidDate = dayjs(date).isValid();
                                      if (isValidDate) {
                                        const formattedDate =
                                          dayjs(date).format('MM/DD');
                                        field.onChange(formattedDate);
                                      } else {
                                        field.onChange('');
                                      }
                                    }}
                                    value={
                                      field.value
                                        ? dayjs(field.value, 'MM/DD')
                                        : null
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                          <div className="form-control">
                            <label htmlFor="email">{t('common-email')}</label>
                            <input
                              maxLength="50"
                              type="text"
                              id="email"
                              {...register('email', {
                                validate: (v) =>
                                  Joi.string()
                                    .email({ tlds: { allow: false } })
                                    .required()
                                    .validate(v),
                              })}
                              readOnly
                            />
                          </div>
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                          <div className="form-control required">
                            <Grid container spacing={1}>
                              <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                                <div className="form-control">
                                  <Input
                                    type="text"
                                    id="prefix_phonee"
                                    label={t('common-prefix')}
                                    error={errors?.prefix_phone}
                                    {...register('prefix_phone', {
                                      validate: (v) => {
                                        const { error } = Joi.string()
                                          .regex(/^\+[0-9]{1,3}$/)
                                          .required()
                                          .error(
                                            () => new Error(t('alert-prefix'))
                                          )
                                          .validate(v);
                                        return error?.message
                                          ? error.message
                                          : true;
                                      },
                                    })}
                                  />
                                </div>
                              </Grid>
                              <Grid size={{ xs: 8, sm: 8, md: 8 }}>
                                <div className="form-control required">
                                  <Input
                                    type="text"
                                    id="phone"
                                    label={t('common-phone')}
                                    error={errors?.phone}
                                    {...register('phone', {
                                      validate: (v) => {
                                        const { error } = Joi.string()
                                          .required()
                                          .error(
                                            () => new Error(t('alert-phone'))
                                          )
                                          .validate(v);
                                        return error?.message
                                          ? error.message
                                          : true;
                                      },
                                    })}
                                  />
                                  {phoneError && (
                                    <p className="error-message">
                                      {phoneError}
                                    </p>
                                  )}
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                      </Grid>
                      {/* Partner information */}
                      {profileStatus === 'active' && (
                        <Grid container spacing={3}>
                          <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                            <div className="form-control">
                              <label htmlFor="name_partner">
                                {t('common-name-partner')}
                              </label>
                              <input
                                maxLength="50"
                                type="text"
                                id="name_partner"
                                {...register('name_partner', {})}
                              />
                            </div>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                            <div className="form-control">
                              <label htmlFor="last_name_partner">
                                {t('common-last-name-partner')}
                              </label>
                              <input
                                maxLength="50"
                                type="text"
                                id="last_name_partner"
                                {...register('last_name_partner', {})}
                              />
                            </div>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 3, md: 4 }}>
                            <div className="form-control datepicker">
                              <label htmlFor="birth_day_partner">
                                {t('common-birthday-partner')}
                              </label>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                  name="birth_day_partner"
                                  control={control}
                                  render={({ field }) => (
                                    <DateField
                                      {...field}
                                      format="DD/MM"
                                      onChange={(date) => {
                                        const isValidDate =
                                          dayjs(date).isValid();
                                        if (isValidDate) {
                                          const formattedDate =
                                            dayjs(date).format('MM/DD');
                                          field.onChange(formattedDate);
                                        } else {
                                          field.onChange('');
                                        }
                                      }}
                                      value={
                                        field.value
                                          ? dayjs(field.value, 'MM/DD')
                                          : null
                                      }
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                    </Collapse>

                    <div
                      className="title"
                      onClick={() => toggleSection('address')}
                    >
                      <h5>{t('common-address-information')}</h5>
                      <span className="btn text">
                        {expandedSections.address ? (
                          <span className="icon-minus"></span>
                        ) : (
                          <span className="icon-plus"></span>
                        )}
                      </span>
                    </div>
                    <Collapse in={expandedSections.address}>
                      {profileStatus === 'active' && (
                        <>
                          <p>{t('pma-profile-choose-address')}</p>
                          <div className="form-control">
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={preferredAddress}
                              onChange={handlePreferredAddressChange}
                            >
                              <FormControlLabel
                                value="private"
                                name="preferred_address"
                                control={<Radio />}
                                label={t('common-private-address')}
                              />
                              <FormControlLabel
                                value="company"
                                name="preferred_address"
                                control={<Radio />}
                                label={t('common-company-address')}
                              />
                            </RadioGroup>
                          </div>
                        </>
                      )}

                      {profileStatus === 'active' && (
                        <div className="address">
                          <div className="address-header">
                            <h5>{t('common-private-address')}</h5>
                            {privateAddress?.id && (
                              <button
                                type="button"
                                className="btn-form btn text"
                                onClick={() => {
                                  handleClearAddress('private');
                                }}
                              >
                                <span className="icon-delete"></span>
                              </button>
                            )}
                          </div>
                          <div className="form-control">
                            <div className="form-control">
                              <Input
                                maxLength="50"
                                type="text"
                                id="alias_private"
                                placeholder="E.g. 'Second residence' or 'Holiday house'"
                                label={t('common-alias')}
                                error={errors?.private_address?.alias}
                                {...register('private_address.alias', {
                                  validate: (value, allValues) =>
                                    validateAddressField('alias', allValues),
                                })}
                              />
                            </div>
                          </div>
                          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                            <div className="form-control">
                              <Grid container spacing={1}>
                                <Grid size={{ xs: 12, sm: 8, md: 8 }}>
                                  <div className="form-control">
                                    <Input
                                      maxLength="150"
                                      type="text"
                                      id="address_line_1_private"
                                      label={t('common-address-name')}
                                      error={
                                        errors?.private_address?.address_line_1
                                      }
                                      {...register(
                                        'private_address.address_line_1',
                                        {
                                          validate: (value, allValues) =>
                                            validateAddressField(
                                              'address_line_1',
                                              allValues
                                            ),
                                        }
                                      )}
                                    />
                                  </div>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                  <div className="form-control">
                                    <Input
                                      maxLength="5"
                                      type="text"
                                      id="address_number_private"
                                      label={t('common-address-number')}
                                      error={
                                        errors?.private_address?.address_number
                                      }
                                      {...register(
                                        'private_address.address_number',
                                        {
                                          validate: (value, allValues) =>
                                            validateAddressField(
                                              'address_number',
                                              allValues
                                            ),
                                        }
                                      )}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <div className="form-control">
                            <div className="form-control">
                              <label htmlFor="address_line_2_private">
                                {t('common-address-line-2')}
                              </label>
                              <input
                                maxLength="150"
                                type="text"
                                id="address_line_2_private"
                                {...register('private_address.address_line_2')}
                              />
                            </div>
                          </div>
                          <Grid container spacing={3}>
                            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                              <div className="form-control">
                                <label htmlFor="country_private">
                                  {t('common-country')}
                                </label>
                                <select
                                  name="area_code_private"
                                  id="country_private"
                                  className="normal"
                                  error={errors?.private_address?.area_code}
                                  {...register('private_address.area_code', {
                                    validate: (value, allValues) =>
                                      validateAddressField(
                                        'area_code',
                                        allValues
                                      ),
                                  })}
                                >
                                  <option key="" value="">
                                    {t('common-select-country')}
                                  </option>
                                  {countries.map((country) => (
                                    <option
                                      key={country.area_code}
                                      value={country.area_code}
                                    >
                                      {t('countries-' + country.area_code)}
                                    </option>
                                  ))}
                                </select>
                                {errors?.private_address?.area_code && (
                                  <span className="input__error">
                                    {errors.private_address.area_code.message}
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                              <div className="form-control">
                                <Input
                                  maxLength="50"
                                  type="text"
                                  id="city_private"
                                  label={t('common-city')}
                                  error={errors?.private_address?.city}
                                  {...register('private_address.city', {
                                    validate: (value, allValues) =>
                                      validateAddressField('city', allValues),
                                  })}
                                />
                              </div>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                              <div className="form-control">
                                <Input
                                  maxLength="50"
                                  type="text"
                                  id="postal_code_private"
                                  label={t('common-zip-cp')}
                                  error={errors?.private_address?.postal_code}
                                  {...register('private_address.postal_code', {
                                    validate: (value, allValues) =>
                                      validateAddressField(
                                        'postal_code',
                                        allValues
                                      ),
                                  })}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      )}

                      {profileStatus === 'active' && (
                        <div className="address">
                          <div className="address-header">
                            <h5>{t('common-company-address')}</h5>
                            {companyAddress?.id && (
                              <button
                                type="button"
                                className="btn-form btn text"
                                onClick={() => handleClearAddress('company')}
                              >
                                <span className="icon-delete"></span>{' '}
                              </button>
                            )}
                          </div>
                          <div className="form-control">
                            <div className="form-control">
                              <Input
                                maxLength="50"
                                type="text"
                                id="company_name"
                                label={t('common-company-name')}
                                error={errors?.company_address?.company_name}
                                {...register('company_address.company_name', {
                                  validate: (value, allValues) =>
                                    validateCompanyField(
                                      'company_name',
                                      allValues
                                    ),
                                })}
                              />
                            </div>
                          </div>
                          <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                            <div className="form-control">
                              <Grid container spacing={1}>
                                <Grid size={{ xs: 12, sm: 8, md: 8 }}>
                                  <div className="form-control">
                                    <Input
                                      maxLength="150"
                                      type="text"
                                      id="address_line_1_company"
                                      label={t('common-address-name')}
                                      error={
                                        errors?.company_address?.address_line_1
                                      }
                                      {...register(
                                        'company_address.address_line_1',
                                        {
                                          validate: (value, allValues) =>
                                            validateCompanyField(
                                              'address_line_1',
                                              allValues
                                            ),
                                        }
                                      )}
                                    />
                                  </div>
                                </Grid>
                                <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                                  <div className="form-control">
                                    <Input
                                      maxLength="5"
                                      type="text"
                                      id="address_number_company"
                                      label={t('common-address-number')}
                                      error={
                                        errors?.company_address?.address_number
                                      }
                                      {...register(
                                        'company_address.address_number',
                                        {
                                          validate: (value, allValues) =>
                                            validateCompanyField(
                                              'address_number',
                                              allValues
                                            ),
                                        }
                                      )}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <div className="form-control">
                            <div className="form-control">
                              <label htmlFor="address_line_2_company">
                                {t('common-address-line-2')}
                              </label>
                              <input
                                maxLength="150"
                                type="text"
                                id="address_line_2_company"
                                {...register('company_address.address_line_2')}
                              />
                            </div>
                          </div>
                          <Grid container spacing={3}>
                            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                              <div className="form-control">
                                <label htmlFor="country_company">
                                  {t('common-country')}
                                </label>
                                <select
                                  name="area_code_company"
                                  id="country_company"
                                  className="normal"
                                  error={errors?.company_address?.area_code}
                                  {...register('company_address.area_code', {
                                    validate: (value, allValues) =>
                                      validateCompanyField(
                                        'area_code',
                                        allValues
                                      ),
                                  })}
                                >
                                  <option key="" value="">
                                    {t('common-select-country')}
                                  </option>
                                  {countries.map((country) => (
                                    <option
                                      key={country.area_code}
                                      value={country.area_code}
                                    >
                                      {t('countries-' + country.area_code)}
                                    </option>
                                  ))}
                                </select>
                                {errors?.company_address?.area_code && (
                                  <span className="input__error">
                                    {errors.company_address.area_code.message}
                                  </span>
                                )}
                              </div>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                              <div className="form-control">
                                <Input
                                  maxLength="50"
                                  type="text"
                                  id="city_company"
                                  label={t('common-city')}
                                  error={errors?.company_address?.city}
                                  {...register('company_address.city', {
                                    validate: (value, allValues) =>
                                      validateCompanyField('city', allValues),
                                  })}
                                />
                              </div>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                              <div className="form-control">
                                <Input
                                  maxLength="50"
                                  type="text"
                                  id="postal_code_company"
                                  label={t('common-zip-cp')}
                                  error={errors?.company_address?.postal_code}
                                  {...register('company_address.postal_code', {
                                    validate: (value, allValues) =>
                                      validateCompanyField(
                                        'postal_code',
                                        allValues
                                      ),
                                  })}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Collapse>

                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 6, md: 6 }}>
                        <button className="btn" type="submit">
                          {t('common-save')}
                        </button>
                      </Grid>
                    </Grid>
                  </form>
                </main>
              </div>
            </section>
          </main>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
}

export default HacProfile;
