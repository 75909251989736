import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 694,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalWindow({
  open,
  handleClose,
  entityId,
  userId,
  postExecuteFunction,
  executeFunction,
  title,
  text,
  feedback,
  extraInfo,
  textButtonCancel,
  textoButtonConfirm
}) {

const [openSnackbar, setOpenSnackbar] = useState(false);

const handleCloseSnackbar = () => {
  setOpenSnackbar(false);
};

const handleDelete = async () => {
  try {
    const token = sessionStorage.getItem('token') || null;
    await executeFunction(token, userId, entityId);
    setOpenSnackbar(true);
    postExecuteFunction();
    
    handleClose();
  } catch (error) {
    console.error('Error al eliminar:', error);
  }
};

return (
  <>
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <button
          className="close"
          onClick={() => {
            handleClose();
          }}
        >
          <span className="icon-close"></span>
        </button>
        <h2>{title}</h2>
        <p>{text}</p>
        <p>
          <strong>{extraInfo?.[0]}</strong>
        </p>
        <p>{extraInfo?.[1]}</p>
        <div className="actions">
          {textButtonCancel && (
            <button className="btn secondary" onClick={handleClose}>
              {textButtonCancel}
            </button>
          )}
          {textoButtonConfirm && (
            <button className="btn" onClick={handleDelete}>
              {textoButtonConfirm}
            </button>
          )}
        </div>
      </Box>
    </Modal>

    {feedback && (
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          {feedback}
        </Alert>
      </Snackbar>
    )}
  </>
);
}

export default ModalWindow;