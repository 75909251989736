import './profile.scss';
import Header from '../../../organisms/header/header.js';
import {Link, Navigate, useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid2';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import {useTranslation} from 'react-i18next';
import decodeToken from '../../../../utils/decode-token';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateField} from '@mui/x-date-pickers/DateField';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Snackbar} from "@mui/material";
import { getModels, upsertVehicle } from '../../../../api/hacApi';
import { fetchRetailers } from '../../../../api/retailerApi.js'

dayjs.extend(customParseFormat);

function HacEditVehicle() {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const hacId = decodeToken(token).id;


  const location = useLocation();
  const vehicle = location.state?.vehicle || {};
  

  const navigate = useNavigate();

  const [purchaseDate, setPurchaseDate] = useState(vehicle.purchase_date || '');
  const {control} = useForm();

  const [model, setModel] = useState(vehicle.model || '');
  const [id] = useState(vehicle.id || '');
  const [licensePlate, setLicensePlate] = useState(vehicle.license_plate || '');
  const [vin, setVin] = useState(vehicle.vin || '');
  const [retailerAreaCode, setRetailerAreaCode] = useState(
    vehicle.retailer_area_code || ''
  );
  const [retailerCity, setRetailerCity] = useState(vehicle.retailer_city || '');
  const [retailerId, setRetailerId] = useState(vehicle.retailer_id || '');
  const [otherModel, setOtherModel] = useState('');
  const [showOther, setShowOther] = useState(
    useState(vehicle.model === 'Other')
  );

  const [retailers, setRetailers] = useState([]);
  const [filteredRetailers, setFilteredRetailers] = useState([]);
  const [citySearch, setCitySearch] = useState('');

  const [models, setModels] = useState([]);
  const [uniqueCountries, setUniqueCountries] = useState([]);

  const [errors, setErrors] = useState({});

  // Estado para controlar la snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const validateForm = () => {
    const newErrors = {};
    if (!model) newErrors.model = 'Please select a model.';
    if (model === 'Other' && !otherModel)
      newErrors.other = 'Other model field is required';
    if (
      licensePlate &&
      (licensePlate.length > 12 || !/^[\w\s-·]+$/.test(licensePlate))
    ) {
      newErrors.licensePlate =
        'License plate must be down to 12 characters long and can only include alphanumeric characters, spaces, - and ·.';
    }
    if (vin && (vin.length > 20 || !/^[a-zA-Z0-9]+$/.test(vin))) {
      newErrors.vin =
        'VIN must be down to 20 characters long and can only include alphanumeric characters.';
    }

    /*    if (retailerAreaCode || retailerCity || retailerId) {
          if (!retailerAreaCode)
            newErrors.retailerAreaCode =
              'Country is required if city or retailer name is provided.';
          if (!retailerCity)
            newErrors.retailerCity =
              'City is required if country or retailer name is provided.';
          if (!retailerId)
            newErrors.retailerId =
              'Retailer name is required if country or city is provided.';
        }*/

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const getRetailers = async () => {
      try {     
        const response = await fetchRetailers(token)

        // Ordenar los retailers por el campo 'name' alfabéticamente
        const sortedRetailers = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        setRetailers(sortedRetailers);

        // Obtener los países únicos
        const uniqueCountries = Array.from(
          new Set(sortedRetailers.map((retailer) => retailer.area_code))
        );
        setUniqueCountries(uniqueCountries);
      } catch (error) {
        console.error('Error fetching retailers:', error);
      }
    };


    const fetchModels = async () => {
      try {   
        const response = await getModels(token);
        setModels(response.data);

        if (vehicle.id) {
          const modelExists = response.data.some(
            (m) => m.model === vehicle.model
          );

          if (!modelExists) {
            setModel('Other');
            setShowOther(true);
            setOtherModel(vehicle.model);
          } else {
            setModel(vehicle.model);
            setShowOther(false);
          }
        } else {
          setModel('');
          setShowOther(false);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    getRetailers();
    fetchModels();
  }, [token, vehicle.id, vehicle.model]);

  useEffect(() => {
    let filtered = retailers;
    if (retailerAreaCode) {
      filtered = filtered.filter(
        (retailer) => retailer.area_code === retailerAreaCode
      );
    }
    if (citySearch) {
      filtered = filtered.filter((retailer) =>
        retailer.city.toLowerCase().includes(citySearch.toLowerCase())
      );
    }
    setFilteredRetailers(filtered);
  }, [retailerAreaCode, citySearch, retailers]);

  useEffect(() => {
    if (models.length > 0 && model) {
      const modelExists = models.some((m) => m.model === model);
      if (!modelExists) {
        setShowOther(true);
      } else {
        setShowOther(false);
      }
    }
  }, [models, model]);

  const handleModelChange = (e) => {
    const selectedModel = e.target.value;

    if (selectedModel === 'Other') {
      setModel('Other');
      setShowOther(true);
      setVin('');
      setRetailerCity('')
      setRetailerAreaCode('');
      setRetailerId('');
    } else {
      setModel(selectedModel);
      setShowOther(false);
    }
  };

  const handleRetailerChange = (e) => {
    const selectedRetailerId = e.target.value;
    setRetailerId(selectedRetailerId);

    if (selectedRetailerId === '') {
      setRetailerAreaCode('');
      setRetailerCity('');
      return;
    }

    const selectedRetailer = filteredRetailers.find(
      (retailer) => retailer.id === parseInt(selectedRetailerId, 10)
    );

    if (selectedRetailer) {
      setRetailerAreaCode(selectedRetailer.area_code || '');
      setRetailerCity(selectedRetailer.city || '');
    } else {
      setRetailerAreaCode('');
      setRetailerCity('');
    }
  };

  const handleCitySearchChange = (e) => {
    setCitySearch(e.target.value);
    setRetailerCity(e.target.value);
  };

  const handleOtherModelChange = (e) => {
    setOtherModel(e.target.value);
  };

  const saveNewVehicle = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const newVehicle = {
      model: model === 'Other' ? otherModel : model,
      retailer_id: retailerId || undefined,
      license_plate: licensePlate || undefined,
      purchase_date: purchaseDate
        ? dayjs(purchaseDate).format('YYYY')
        : undefined,
      vin: vin || undefined,
      id: id || undefined,
    };

  /*  const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };*/

    /*  apiClient
      .post(`${process.env.REACT_APP_API_URL}/hac/vehicle`, newVehicle, config)
      .then((response) => {*/
    //  })
    try {
      await upsertVehicle(token, hacId, newVehicle);
      navigate('/hac/profile/vehicles', { state: { openSnackbar: true } });
    } catch (error) {
      console.error('Error saving vehicle:', error);
      setSnackbarMessage(t('common-internal-server-error'));
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const cancelNewVehicle = () => {
    navigate(`/hac/profile/vehicles`);
  };

  // Cerrar snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header />
          <main>
            <Wadsworth />
            <section>
              <div className="inner menu">
                <aside className="lateral-menu">
                  <h2>{t('hac-menu-profile-title')}</h2>
                  <ul>
                    <li>
                      <Link to="/hac/profile/basic-info">
                        {t('hac-menu-profile-menu1')}
                      </Link>
                    </li>
                    <li className="on">
                      <Link to="/hac/profile/vehicles">
                        {t('hac-menu-profile-menu2')}
                      </Link>
                    </li>
                  </ul>
                </aside>
                <main>
                  <Link to="/hac/profile/vehicles" className="back-button">
                    <span className="icon-chevron-left"></span>{' '}
                    {t('common-back')}
                  </Link>
                  {!id && (
                    <>
                      <h4>{t('common-new-vehicle')}</h4>
                      <p>{t('hac-vehicle-new-vehicle-text')}</p>
                    </>
                  )}
                  <form className="edit-vehicle">
                    <h5>{t('common-vehicle-details')}</h5>
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                        <div className="form-control">
                          <label htmlFor="model">{t('common-model')}</label>
                          <select
                            name="model"
                            id="model"
                            className="normal"
                            value={model}
                            onChange={handleModelChange}
                          >
                            <option key="" value="">
                              {t('common-select-model')}
                            </option>
                            {models.map((m, index) => (
                              <option key={index} value={m.model}>
                                {m.model}
                              </option>
                            ))}                        

                            <option key="other" value="Other">
                              {t('common-other')}
                            </option>
                          </select>
                          {errors.model && (
                            <p className="error">{errors.model}</p>
                          )}
                        </div>
                      </Grid>
                      {showOther && (
                        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                          <div className="form-control">
                            <label htmlFor="other">{t('common-other')}</label>
                            <input
                              type="text"
                              name="other"
                              id="other"
                              value={otherModel}
                              onChange={handleOtherModelChange}
                            />
                            {errors.other && (
                              <p className="error">{errors.other}</p>
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                        <div className="form-control">
                          <label htmlFor="license_plate">
                            {t('common-license-plate')}
                          </label>
                          <input
                            type="text"
                            name="license_plate"
                            id="license_plate"
                            value={licensePlate}
                            onChange={(e) => setLicensePlate(e.target.value)}
                          />
                          {errors.licensePlate && (
                            <p className="error">{errors.licensePlate}</p>
                          )}
                        </div>
                      </Grid>
                      {!showOther && (
                        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                          <div className="form-control">
                            <label htmlFor="vin">{t('common-vin')}</label>
                            <input
                              type="text"
                              name="vin"
                              id="vin"
                              value={vin}
                              onChange={(e) => setVin(e.target.value)}
                            />
                            {errors.vin && (
                              <p className="error">{errors.vin}</p>
                            )}
                          </div>
                        </Grid>
                      )}
                      <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                        <div className="form-control datepicker">
                          <label htmlFor="purchaseDate">
                            {t('common-purchase-date')}
                          </label>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Controller
                              name="purchaseDate"
                              defaultValue={purchaseDate}
                              control={control}
                              render={({ field }) => (
                                <DateField
                                  {...field}
                                  format="YYYY"
                                  onChange={(date) => {
                                    const isValidDate = dayjs(date).isValid();
                                    if (isValidDate) {
                                      const formattedDate =
                                        dayjs(date).format('YYYY-MM-DD');
                                      setPurchaseDate(formattedDate);
                                      field.onChange(formattedDate);
                                    } else {
                                      setPurchaseDate('');
                                      field.onChange('');
                                    }
                                  }}
                                  value={
                                    field.value
                                      ? dayjs(field.value, 'YYYY')
                                      : null
                                  }
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>
                    {!showOther && (
                      <>
                        <p>{t('common-retailer-details')}</p>
                        <Grid container spacing={3}>
                          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                            <div className="form-control">
                              <label htmlFor="retailer_area_code">
                                {t('common-country')}
                              </label>
                              <select
                                name="retailer_area_code"
                                id="retailer_area_code"
                                value={retailerAreaCode}
                                onChange={(e) =>
                                  setRetailerAreaCode(e.target.value)
                                }
                                className="normal"
                              >
                                <option value="">
                                  {t('common-select-country')}
                                </option>
                                {uniqueCountries.map((countryCode) => (
                                  <option key={countryCode} value={countryCode}>
                                    {t(`countries-${countryCode}`)}
                                  </option>
                                ))}
                              </select>
                              {errors.retailerAreaCode && (
                                <p className="error">
                                  {errors.retailerAreaCode}
                                </p>
                              )}
                            </div>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
                            <div className="form-control">
                              <label htmlFor="retailer_city">
                                {t('common-city')}
                              </label>
                              <input
                                type="text"
                                id="retailer_city"
                                name="retailer_city"
                                value={retailerCity}
                                onChange={handleCitySearchChange}
                              />
                              {errors.retailerCity && (
                                <p className="error">{errors.retailerCity}</p>
                              )}
                            </div>
                          </Grid>
                          <Grid size={{ xs: 12, sm: 12, md: 12 }}>
                            <div className="form-control">
                              <label htmlFor="retailer_name">
                                {t('common-retailer-name')}
                              </label>
                              <select
                                name="retailer_name"
                                id="retailer_name"
                                value={retailerId}
                                onChange={handleRetailerChange}
                                className="normal"
                              >
                                <option value="">
                                  {t('common-select-retailer')}
                                </option>
                                {filteredRetailers.map((retailer) => (
                                  <option key={retailer.id} value={retailer.id}>
                                    {retailer.name} ({retailer.city})
                                  </option>
                                ))}
                              </select>
                              {errors.retailerId && (
                                <p className="error">{errors.retailerId}</p>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <div className="actions">
                      <button
                        className="btn secondary"
                        onClick={cancelNewVehicle}
                      >
                        {t('common-cancel')}
                      </button>
                      <button className="btn" onClick={saveNewVehicle}>
                        {t('hac-my-profile-vehicles-edit-action')}
                      </button>
                    </div>
                  </form>
                </main>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default HacEditVehicle;
