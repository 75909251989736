const ROLES = {
  SuperAdmin: 'superAdmin',
  ShippingAdmin: 'shippingAdmin',
  Admin: 'admin',
  Retailer: 'retailer',
  RetailerGM: 'retailerGM',
  Hac: 'hac',
  Pma: 'pma',
  PmaGM: 'pmaGM',
  MarketAdmin: 'marketAdmin',
  CountryAdmin: 'countryAdmin',
};

const HAC_STATUS = {
  Nominated: 'nominated',
  Exited: 'exited',
  Active: 'active',
};

export { ROLES, HAC_STATUS };
