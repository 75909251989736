import './table-hac.scss';
import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useNavigate} from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid2';
import {useTranslation} from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import decodeToken from '../../../../utils/decode-token';
import ModalNominateOnBehalf from '../modal-nominate-on-behalf/modal-nominate-on-behalf';
import {Alert, Snackbar, Tooltip, TableSortLabel} from '@mui/material';
import { ROLES } from '../../../../constants/constants';


function TableHac({reload, onDataLoaded}) {
  const {t} = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const navigate = useNavigate();
  const [dataLoaded, setDataLoaded] = useState(false);
  const userId = decodeToken(token).id;
  const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser') || null);
  const canNominate = loggedUser?.nominate;


  const [hacs, setHacs] = useState([]);
  const [filteredHacs, setFilteredHacs] = useState([]);
  const [countries, setCountries] = useState([]);
  const [retailers, setRetailers] = useState([]);
  const [initialMarkets] = useState([
    { name: 'AU_CZ_SK', countries: ['AT', 'CZ', 'SK'] },
    { name: 'Benelux', countries: ['BE', 'LUX', 'NL'] },
    { name: 'EIRO', countries: ['PL'] },
    { name: 'France', countries: ['FR'] },
    { name: 'Germany', countries: ['DE'] },
    { name: 'Iberia', countries: ['ES', 'PT'] },
    { name: 'Italy', countries: ['IT'] },
    { name: 'Switzerland', countries: ['CH'] },
    { name: 'Turkey', countries: ['TR'] },
  ]);
  const markets = initialMarkets.filter(market => loggedUser?.market.includes(market.name));

  const [filters, setFilters] = useState({
    name: '',
    market: '',
    country: '',
    status: '',
    retailer: ''
  });
  const [anchorEl, setAnchorEl] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          console.error('No se encontró ningún token en sessionStorage.');
          return;
        }

        let url = '';
        if (
          role === ROLES.Admin ||
          role === ROLES.MarketAdmin ||
          role === ROLES.CountryAdmin
        ) {
          url = `${process.env.REACT_APP_API_URL}/admin/${userId}`;
        }

        if (!url) {
          console.error('No se pudo construir la URL para la solicitud.');
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept-Encoding': 'identity'
          },
          decompress: false
        };

        if (role === ROLES.MarketAdmin) {
          const response = await apiClient.get(url, config);
          if (response.data && response.data.market_area_code) {
            //setAssociatedMarket(response.data.market_area_code);
          } else {
            console.warn('No se encontró market_area_code en la respuesta.');
          }
        }
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [token, userId, role]);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        console.error('No se encontró ningún token en sessionStorage.');
        return;
      }

      const authToken = token;
      const url = `${process.env.REACT_APP_API_URL}/admin/hacs`;

      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Cache-Control': 'no-cache',
        },
      };

      try {
        const response = await apiClient.get(url, config);
        if (response.status === 200) {
          const sortedHacs = response.data.sort((a, b) => {
            const [dayA, monthA, yearA] = a.last_update.split('-').map(Number);
            const [dayB, monthB, yearB] = b.last_update.split('-').map(Number);
            const dateA = new Date(yearA, monthA - 1, dayA);
            const dateB = new Date(yearB, monthB - 1, dayB);
            return dateB - dateA;
          });

          // Recuperar filtros almacenados y aplicar si existen
          const storedFilters = JSON.parse(sessionStorage.getItem('admin-hac-filters') || '{}');
          const initialFilters = {
            ...filters,
            ...storedFilters,
          };
          setFilters(initialFilters);
          setHacs(sortedHacs);
          applyFilters(initialFilters, sortedHacs); // Aplicar los filtros guardados al cargar

          const market = markets.find((m) => m.name === initialFilters.market);
          const availableCountries = market ? market.countries : [...new Set(response.data.map((hac) => hac.area_code))];

          const uniqueRetailers = [...new Set(response.data.map(hac => hac.retailer))].filter(Boolean);
          setRetailers(uniqueRetailers);

          setCountries(orderCountries(availableCountries));

          setDataLoaded(true);
        } else if (response.status === 204) {
          setDataLoaded(true);
        }
      } catch (error) {
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      }
    };

    if (!dataLoaded) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [token, dataLoaded]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === "market") {
      if (value === "") {
        // Si se selecciona "All Markets", mostrar todos los países y retailers disponibles
        const allCountries = [...new Set(hacs.map((hac) => hac.area_code))];
        const allRetailers = [...new Set(hacs.map((hac) => hac.retailer))].filter(Boolean);

        setCountries(orderCountries(allCountries));
        setRetailers(allRetailers);

        setFilters({
          ...filters,
          market: "",
          country: "",
          retailer: "",
        });
      } else {
        const selectedMarket = markets.find((market) => market.name === value);
        const updatedCountries = selectedMarket ? selectedMarket.countries : [...new Set(hacs.map((hac) => hac.area_code))];

        setCountries(orderCountries(updatedCountries));

        // Si hay un solo país en ese market, seleccionarlo automáticamente
        const autoSelectedCountry = updatedCountries.length === 1 ? updatedCountries[0] : "";

        // Filtrar retailers solo para los países del market seleccionado
        const filteredRetailers = hacs
          .filter((hac) => selectedMarket?.countries.includes(hac.area_code))
          .map((hac) => hac.retailer)
          .filter((retailer, index, self) => retailer && self.indexOf(retailer) === index); // Eliminar duplicados

        setRetailers(filteredRetailers);

        setFilters({
          ...filters,
          market: value,
          country: autoSelectedCountry, // Auto-selecciona el país si hay solo uno
          retailer: "",
        });
      }
    } else if (name === "country") {
      if (value === "") {
        // Si se selecciona "All Countries", mostrar todos los retailers disponibles para el market actual
        const currentMarket = markets.find((market) => market.name === filters.market);
        const allRetailers = currentMarket
          ? hacs
            .filter((hac) => currentMarket.countries.includes(hac.area_code))
            .map((hac) => hac.retailer)
            .filter((retailer, index, self) => retailer && self.indexOf(retailer) === index)
          : [...new Set(hacs.map((hac) => hac.retailer))].filter(Boolean);

        setRetailers(allRetailers);

        setFilters({
          ...filters,
          country: "",
          retailer: "",
        });
      } else {
        // Filtrar el Market al que pertenece el país seleccionado
        const relatedMarket = markets.find((market) => market.countries.includes(value))?.name || filters.market;

        // Filtrar retailers solo para el país seleccionado
        const filteredRetailers = hacs
          .filter((hac) => hac.area_code === value)
          .map((hac) => hac.retailer)
          .filter((retailer, index, self) => retailer && self.indexOf(retailer) === index); // Eliminar duplicados

        setRetailers(filteredRetailers);

        setFilters({
          ...filters,
          country: value,
          market: relatedMarket, // Auto-selecciona el Market correspondiente
          retailer: "",
        });
      }
    } else if (name === "retailer") {
      // Filtrar el mercado y país correspondiente al retailer seleccionado
      const relatedCountries = hacs
        .filter((hac) => hac.retailer === value)
        .map((hac) => hac.area_code)
        .filter((country, index, self) => country && self.indexOf(country) === index); // Eliminar duplicados

      const relatedMarkets = markets
        .filter((market) => market.countries.some((country) => relatedCountries.includes(country)))
        .map((market) => market.name);

      // Determinar si hay solo un mercado y país
      const selectedCountry = relatedCountries.length === 1 ? relatedCountries[0] : filters.country;
      const selectedMarket = relatedMarkets.length === 1 ? relatedMarkets[0] : filters.market;

      setFilters({
        ...filters,
        retailer: value,
        country: selectedCountry,
        market: selectedMarket,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };



  const applyFilters = (appliedFilters = filters, hacsData = hacs) => {
    let filtered = [...hacsData];

    if (appliedFilters.name) {
      filtered = filtered.filter(
        (hac) =>
          hac.name.toLowerCase().includes(appliedFilters.name.toLowerCase()) ||
          hac.last_name.toLowerCase().includes(appliedFilters.name.toLowerCase())
      );
    }

    if (appliedFilters.market) {
      const selectedMarket = markets.find(
        (market) => market.name === appliedFilters.market
      );
      if (selectedMarket) {
        filtered = filtered.filter((hac) =>
          selectedMarket.countries.includes(hac.area_code)
        );
      }
    }

    if (appliedFilters.country) {
      filtered = filtered.filter((hac) => hac.area_code === appliedFilters.country);
    }

    if (appliedFilters.status) {
      filtered = filtered.filter((hac) => hac.status === appliedFilters.status);
    }

    if (appliedFilters.retailer) {
      filtered = filtered.filter((hac) => hac.retailer === appliedFilters.retailer);
    }

    const uniqueFiltered = Array.from(new Map(filtered.map(item => [item.id, item])).values()); // fix para cuando llegan duplicados.
    setFilteredHacs(uniqueFiltered);
    sessionStorage.setItem('admin-hac-filters', JSON.stringify(appliedFilters));
  };


  const handleApplyFilter = () => {
    applyFilters();
  };

  const resetFilters = () => {
    const initialFilters = {
      name: '',
      market: '',
      country: '',
      status: '',
      retailer: ''
    };

    const allCountries = [...new Set(hacs.map((hac) => hac.area_code))];
    const allRetailers = [...new Set(hacs.map((hac) => hac.retailer))].filter(Boolean);

    setFilters(initialFilters);
    setCountries(orderCountries(allCountries));
    setRetailers(allRetailers);

    sessionStorage.setItem('admin-hac-filters', JSON.stringify(initialFilters));

    applyFilters(initialFilters, hacs);
  };



  const handleClick = (id) => (event) => {
    setAnchorEl({...anchorEl, [id]: event.currentTarget});
  };

  const handleClose = () => {
    setAnchorEl({});
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const orderCountries = (countries) => {
    return countries
      .map(country => ({ [country]: t(`countries-${country}`) }))
      .sort((a, b) => Object.values(a)[0].localeCompare(Object.values(b)[0]));
  };

  const getEmailStatus = (row) => {

    let status = '';

    if (row.email_status?.bounce) {
      status = t('common-edm-bounced');
    }
    if (row.email_status?.send && !row.email_status.open && !row.email_status.click) {
      status = t('common-edm-send')
    }
    if (row.email_status?.send && row.email_status.open && !row.email_status.click) {
      status = t('common-edm-open')
    }
    if (row.email_status?.send && row.email_status.open && row.email_status.click) {
      status = t('common-edm-click')
    }

/*

    if (row.email_status?.expired === 1) {
      status +=  ' - ' + (t('common-expiring-soon'));
    } else if (row.email_status?.expired === 2) {
      status += ' - ' +  (t('common-expired'));
    }
*/

    return status
  };

  // Función de manejo del orden
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortData = (array) => {
    return array.sort((a, b) => {
      if (orderBy === 'name') {
        return order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      if (orderBy === 'last_name') {
        return order === 'asc'
          ? a.last_name.localeCompare(b.last_name)
          : b.last_name.localeCompare(a.last_name);
      }
      if (orderBy === 'first_pma_email') {
        // Convertimos null en un string vacío para evitar errores
        const emailA = a.first_pma_email || '';
        const emailB = b.first_pma_email || '';

        // Primero, los vacíos van al final
        if (emailA === '' && emailB !== '') return 1;
        if (emailA !== '' && emailB === '') return -1;

        // Luego, ordenamos alfabéticamente
        return order === 'asc'
          ? emailA.localeCompare(emailB)
          : emailB.localeCompare(emailA);
      }
      if (orderBy === 'last_update') {
        return order === 'asc'
          ? new Date(a.last_update) - new Date(b.last_update)
          : new Date(b.last_update) - new Date(a.last_update);
      }
      return 0;
    });
  };

  return (
    <>
      <header>
        <h2>{t('admin-main-tab1-title')}</h2>
        {canNominate ? (
          <button className="btn secondary" onClick={handleOpenModal}>
            <span className="icon-plus"></span> {t('admin-main-tab1-action')}
          </button>
        ) : null}
      </header>
      <ModalNominateOnBehalf open={openModal} onClose={handleCloseModal} />
      {dataLoaded ? (
        hacs && hacs.length !== 0 ? (
          <>
            <div className="filters">
              <p>{t('common-search')}</p>
              <Grid container spacing={3} rowSpacing={0}>
                <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                  <div className="form-control">
                    <label htmlFor="name">
                      {t('common-name')}/{t('common-last-name')}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={filters.name}
                      onChange={handleFilterChange}
                    />
                  </div>
                </Grid>
                {(role === ROLES.Admin || role === ROLES.MarketAdmin) && markets.length > 1 && (
                  <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                    <div className="form-control">
                      <label htmlFor="market">{t('common-market')}</label>
                      <select
                        name="market"
                        id="market"
                        className="normal"
                        value={filters.market}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-market')}</option>
                        {markets
                          .filter(market => loggedUser.market.includes(market.name))
                          .map(market => (
                            <option key={market.name} value={market.name}>
                              {t(`market-${market.name}`)}
                            </option>
                          ))}
                      </select>
                    </div>
                  </Grid>
                )}
                {(role === ROLES.Admin || role === ROLES.MarketAdmin) && (markets.length > 1 || countries.length > 1)  && (
                  <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                    <div className="form-control">
                      <label htmlFor="country">{t('common-country')}</label>
                      <select
                        name="country"
                        id="country"
                        className="normal"
                        value={filters.country}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t('common-all-country')}</option>
                        {countries.map((country) => (
                          <option key={Object.keys(country)[0]} value={Object.keys(country)[0]}>
                            {Object.values(country)[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Grid>
                )}
                <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                  <div className="form-control">
                    <label htmlFor="status">{t('common-status')}</label>
                    <select
                      name="status"
                      id="status"
                      className="normal"
                      value={filters.status}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-status')}</option>
                      <option value="nominated">{t('common-nominated')}</option>
                      <option value="active">{t('common-active')}</option>
                      <option value="exited">{t('common-exited')}</option>
                    </select>
                  </div>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 5 }}>
                  <div className="form-control">
                    <label htmlFor="status">{t('common-retailer')}</label>
                    <select
                      name="retailer"
                      id="retailer"
                      className="normal"
                      value={filters.retailer}
                      onChange={handleFilterChange}
                    >
                      <option value="">{t('common-all-retailers')}</option>
                      {retailers.map((retailer) => (
                        <option key={retailer} value={retailer}>
                          {retailer}
                        </option>
                      ))}
                    </select>

                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                  <div className="actions">
                    <button className="btn" onClick={handleApplyFilter}>
                      {t('common-apply')}
                    </button>
                  </div>
                </Grid>
                <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                  <div className="actions">
                    <button onClick={resetFilters} className="btn text narrow">
                      {t('common-reset-filters')}
                    </button>
                  </div>
                </Grid>
              </Grid>
              <br />
              <br />
              <div className="summary">
                <strong>
                  {t('common-total-results')}:{' '}
                  <span>{filteredHacs.length}</span>
                </strong>{' '}
                |
                <span>
                  {t('common-users-active')}:{' '}
                  <span>
                    {
                      filteredHacs.filter((hac) => hac.status === 'active')
                        .length
                    }
                  </span>
                </span>{' '}
                |
                <span>
                  {t('common-users-nominated')}:{' '}
                  <span>
                    {
                      filteredHacs.filter((hac) => hac.status === 'nominated')
                        .length
                    }
                  </span>
                </span>
              </div>
            </div>

            <div className="table-wrapper">
              {filteredHacs.length !== 0 ? (
                <Table
                  aria-label="simple table"
                  className="user-table admin-hac"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === 'name'}
                          direction={orderBy === 'name' ? order : 'asc'}
                          onClick={() => handleRequestSort('name')}
                        >
                          <span className="text">{t('common-name')}</span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === 'last_name'}
                          direction={orderBy === 'last_name' ? order : 'asc'}
                          onClick={() => handleRequestSort('last_name')}
                        >
                          <span className="text">{t('common-last-name')}</span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={orderBy === 'first_pma_email'}
                          direction={orderBy === 'first_pma_email' ? order : 'asc'}
                          onClick={() => handleRequestSort('first_pma_email')}
                        >
                          <span className="text">{t('common-role-pma')}</span>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text">{t('common-retailer')}</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text">{t('common-market')}</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text">{t('common-country')}</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text">{t('common-status')}</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text">{t('common-last-update')}</span>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortData(filteredHacs).map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">
                          <span className="text">{row.name}</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">{row.last_name}</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">{row.first_pma_email}</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">{row.retailer}</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">
                            {t(`market-${row.market}`)}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">
                            {t(`countries-${row.area_code}`)}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">
                            {row.status === 'nominated' ? (
                              <Tooltip
                                title={getEmailStatus(row)}
                                arrow
                                placement="top"
                                slotProps={{ popper: { disablePortal: true } }}
                              >
                                <span className="text">
                                  <span
                                    className={
                                      row.login_status === 'ready'
                                        ? 'logged'
                                        : ''
                                    }
                                  >
                                    <span className="text">
                                      {t(`common-${row.status}`)}
                                    </span>
                                    <span className="tri"></span>
                                  </span>
                                </span>
                              </Tooltip>
                            ) : (
                              <>
                                <span className="text">
                                  {t(`common-${row.status}`)}
                                </span>
                              </>
                            )}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text">
                            {row.last_update}
                            {row.notification === true && (
                              <span className="update"></span>
                            )}
                          </span>
                        </TableCell>
                        <TableCell align="right">
                          {row.status && row.status !== 'exited' ? (
                            <>
                              <span
                                className="icon-more"
                                onClick={handleClick(row.id)}
                              ></span>
                              <Menu
                                className="submenu"
                                anchorEl={anchorEl[row.id]}
                                open={Boolean(anchorEl[row.id])}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() =>
                                    navigate(
                                      `/common/personal-details/${row.id}`,
                                      {
                                        state: {
                                          breadcrumbName:
                                            row.name + ' ' + row.last_name,
                                        },
                                      }
                                    )
                                  }
                                >
                                  {t('pma-hac-tab-option1')}
                                </MenuItem>
                                {row.status === 'active' ? (
                                  <>
                                    <MenuItem
                                      onClick={() =>
                                        navigate(`/common/vehicles/${row.id}`, {
                                          state: {
                                            breadcrumbName:
                                              row.name + ' ' + row.last_name,
                                          },
                                        })
                                      }
                                    >
                                      {t('pma-hac-tab-option2')}
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() =>
                                        navigate(
                                          `/common/preferences/${row.id}`,
                                          {
                                            state: {
                                              breadcrumbName:
                                                row.name + ' ' + row.last_name,
                                            },
                                          }
                                        )
                                      }
                                    >
                                      {t('pma-hac-tab-option3')}
                                    </MenuItem>
                                  </>
                                ) : null}

                                <MenuItem
                                  onClick={() =>
                                    navigate(
                                      `/admin/nominating-retailer/${row.id}`,
                                      {
                                        state: {
                                          breadcrumbName:
                                            row.name + ' ' + row.last_name,
                                        },
                                      }
                                    )
                                  }
                                >
                                  {t('admin-main-tab1-option4')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    navigate(`/admin/assigned-pmas/${row.id}`, {
                                      state: {
                                        breadcrumbName:
                                          row.name + ' ' + row.last_name,
                                      },
                                    })
                                  }
                                >
                                  {t('admin-main-tab1-option5')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    navigate(`/common/activity-log/${row.id}`, {
                                      state: {
                                        breadcrumbName:
                                          row.name + ' ' + row.last_name,
                                      },
                                    })
                                  }
                                >
                                  {t('pma-hac-tab-option8')}
                                  {row.notification === true && (
                                    <span className="update"></span>
                                  )}
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    navigate(`/admin/shared-notes/${row.id}`, {
                                      state: {
                                        breadcrumbName:
                                          row.name + ' ' + row.last_name,
                                      },
                                    })
                                  }
                                >
                                  {t('pma-hac-tab-option7')}
                                </MenuItem>
                              </Menu>
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="no-result">
                  <h4 className="txt-center">{t('admin-main-title-empty')}</h4>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="no-result">
            <h2 className="txt-center">
              {t('admin-main-tab1-title-empty')}
              <small>{t('admin-main-tab1-text-empty')}</small>
            </h2>
          </div>
        )
      ) : (
        <div>{t('common-loading')}</div>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TableHac;
