import './expired-invitation.scss';
import Header from '../../../organisms/header/header.js'
import {useTranslation} from "react-i18next";
import { useParams } from 'react-router-dom';
import {useEffect} from "react";
import decodeToken from "../../../../utils/decode-token";
import i18n from 'i18next';

function ExpiredInvitation() {

  const { t } = useTranslation();

  const { token } = useParams();
  console.log(token);

  useEffect(() => {
    if (typeof token === 'string' && token.trim() !== '') {
      try {
        const preferredLanguage = decodeToken(token).language || navigator.language;
        console.log(preferredLanguage);
        if (preferredLanguage) {
          i18n.changeLanguage(preferredLanguage.toLowerCase());
        }
      } catch (error) {
        console.error('Failed to decode token:', error);
      }
    } else {
      console.warn('Invalid or missing token:', token);
    }
  }, [token, i18n]);


  return (
    <>
      <div className="App">
        <Header/>
        <main>
          <section className="error">
            <div className="inner">
              <strong className="code">{t('error-generic-code')}</strong>
              <h2>{t('error-expired-title')}</h2>
              <p>{t('error-expired-text')}</p>
            </div>
          </section>
        </main>
      </div>
    </>
  )
}

export default ExpiredInvitation;