import './preferences.scss';
import React from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import { useTranslation } from 'react-i18next';
import PreferencesHac from '../../../organisms/common/hac-preferences/org-hac-preferences';
import { ROLES } from '../../../../constants/constants.js';

const HacListPreferences = () => {
  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const location = useLocation();
  const { breadcrumbName } = location.state || {};

  return (
    <>
      {token &&
      (role === ROLES.Pma ||
        role === ROLES.PmaGM ||
        role === ROLES.Admin ||
        role === ROLES.CountryAdmin ||
        role === ROLES.MarketAdmin ||
        role === ROLES.Retailer ||
        role === ROLES.RetailerGM) ? (
        <div className="App">
          <Header />
          <main>
            <section>
              <div className="inner">
                <Link
                  to={`/${
                    role === ROLES.Admin ||
                    role === ROLES.CountryAdmin ||
                    role === ROLES.MarketAdmin
                      ? `${ROLES.Admin}/main`
                      : role === ROLES.Pma || role === ROLES.PmaGM
                      ? `${ROLES.Pma}/main`
                      : role === ROLES.Retailer || role === ROLES.RetailerGM
                      ? `${ROLES.Retailer}/main/active`
                      : `${role}/main`
                  }`}
                  className="back-button"
                >
                  <span className="icon-chevron-left"></span> {t('common-back')}{' '}
                  / {breadcrumbName}
                </Link>
                <h4>{t('pma-preferences-title')}</h4>
                <main className="preferences">
                  <PreferencesHac />
                </main>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default HacListPreferences;
