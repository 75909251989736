import React from "react";
import "./input.scss";
import "../../assets/scss/_variables.scss";
import clsx from "clsx";

function Input({ id, label, error, className, ...props }, ref) {
  return (
    <>
      <label
        htmlFor={id}
        className={clsx("input_label", { input_label__error: error })}
      >
        {label}
      </label>
      <input
        maxLength="50"
        id={id}
        ref={ref}
        {...props}
        className={clsx(className, { error: error })}
      />
      {error && <p className="input__error">{error.message}</p>}
    </>
  );
}
Input.displayName = "Input";

export default React.forwardRef(Input);
