import './legal.scss';
import React from 'react';
import {Link, Navigate} from 'react-router-dom';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Wadsworth from '../../../organisms/role-hac/wadsworth/wadsworth';
import { useTranslation } from 'react-i18next';
import Footer from "../../../organisms/footer/footer";

const HacLegalPrivacy = () => {

  const { t } = useTranslation();

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;

  const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

  return (
    <>
      {token && role === 'hac' ? (
        <div className="App">
          <Header />
          <main className="legal">
            <Wadsworth />
            <section className="legal">
              <div className="inner">
                <Link to="/hac/main" className="back-button">
                  <span className="icon-chevron-left"></span>{' '}
                  {t('common-back')}
                </Link>
                {loggedUser && loggedUser.preferredLanguage === 'en' ? (
                  <>
                    <h2>{t(`legal-privacy-title-${loggedUser.preferredLanguage}-${loggedUser.area_code}`)}</h2>
                    <p dangerouslySetInnerHTML={{__html: t(`legal-privacy-text-${loggedUser.preferredLanguage}-${loggedUser.area_code}`)}}></p>
                  </>
                ) : (
                  <>
                    <h2>{t('legal-privacy-title')}</h2>
                    <p dangerouslySetInnerHTML={{__html: t('legal-privacy-text')}}></p>
                  </>
                )}
              </div>
            </section>
          </main>
          <Footer />
        </div>
      ) : (
        <Navigate to="/login" />
      )}

    </>
  );
};

export default HacLegalPrivacy;
